<template>
  <div>
    <m-head-bar :bookhandle="bookhandle"></m-head-bar>
    <div class="contentWrap">
      <slot></slot>
    </div>

    <m-footer-bar></m-footer-bar>
  </div>
</template>

<script>
// @ is an alias to /src
import HeadBar from "@/components/HeaderBar_en";
import FooterBar from "@/components/FooterBar_en";
// import FloatBar from "@/components/en/FloatBar";
export default {
  name: "m-layout-bar",
  components: {
    [HeadBar.name]: HeadBar,
    [FooterBar.name]: FooterBar
    // [FloatBar.name]: FloatBar
  },
  methods: {
    bookhandle() {
      // console.log("我是layout1");
      // this.$emit("bookhandle");
      // console.log("我是layout2");
    }
  }
};
</script>

<style scoped lang="scss" rel="stylesheet/scss">
@import "src/assets/styles/variables.scss";
.contentWrap {
  min-height: 100%;
}
</style>

