<template>
  <div class="floatBarWrap">
    <ul>
      <li
        class="item ticket ilarge"
        :class="{'active':activeName==='ticket'}"
        data-index="0"
        data-name="ticket"
        @click="goLocation"
      >
        <router-link to="">
          <img :src="require('../assets/image/head/order.png')" alt />
          <span>票务预订</span>
        </router-link>
      </li>
      <li
        class="item eat ilarge"
        :class="{'active':activeName==='eat'}"
        data-index="0"
        data-name="eat"
      >
        <router-link to="/food/xiCan">
          <img :src="require('../assets/image/head/eat.png')" alt />
          <span>吃得溜溜</span>
        </router-link>
      </li>
      <li
        class="item sleep ilarge"
        :class="{'active':activeName==='sleep'}"
        data-index="0"
        data-name="sleep"
      >
        <router-link to="/stay">
          <img :src="require('../assets/image/head/sleep.png')" alt />
          <span>住得溜溜</span>
        </router-link>
      </li>
      <li
        class="item playGame ilarge"
        :class="{'active':activeName==='playGame'}"
        data-index="0"
        data-name="playGame"
      >
        <router-link to="/play">
          <img :src="require('../assets/image/head/playGame.png')" alt />
          <span>玩得溜溜</span>
        </router-link>
      </li>
      <li
        class="item shopping ilarge"
        :class="{'active':activeName==='shopping'}"
        data-index="0"
        data-name="shopping"
      >
        <router-link to="/buy">
          <img :src="require('../assets/image/head/shopping.png')" alt />
          <span>购得溜溜</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script type="text/ecmascript-6">
import mx_utils from "@/mixins/mx_utils";
export default {
  name: "m-float-bar",
  data(){
    return {
      activeName:""
    }
  },
  mixins:[mx_utils]
};
</script>

<style scoped lang="scss" rel="stylesheet/scss">
@import "src/assets/styles/variables.scss";
.floatBarWrap {
    position: fixed;
    top: 35%;
    right: 0px;
    z-index: 99998;
  .item {
    width: 90px;
    height: 90px;

    a:-webkit-any-link {
      color: $main-white;
    }
     > a,
          > a:focus,
          > a:hover,
          > a.active {
            color: $main-white;
          }
    a {
      display: block;
      width: 100%;
      height: 100%;
      text-align: center;
      box-sizing: border-box;
      padding-top: 16px;
      img {
        max-height: 34px;
        margin-bottom: 8px;
      }
      span {
        display: block;
        margin-bottom: 6px;
        font-size: 13px;
      }
    }
    &.ticket {
      background: $main-red;
      margin-bottom:13px;
    }
    &.eat {
      background: $main-yellow;
    }
    &.sleep {
      background: $main-greenEat;
    }
    &.playGame {
      background: $main-purple;
    }
    &.shopping {
      background: $main-blueButton;
    }

    // &.ticket.active {
    //   background: $main-red;
    // }
    // &.eat.active {
    //   background: $main-yellow;
    // }
    // &.sleep.active {
    //   background: $main-greenEat;
    // }
    // &.playGame.active {
    //   background: $main-purple;
    // }
    // &.shopping.active {
    //   background: $main-blueButton;
    // }
  }
  .item:nth-of-type(1) {
    border-top-left-radius: 4px;
  }
  .item:nth-of-type(5) {
    border-bottom-left-radius: 4px;
  }
}
@media (max-width: $screen-sp-min){
  .floatBarWrap {
    top: 25%;

  }
}
</style>
