export default {
  data() {
    return {
      name: 'mx_utils'
    }
  },
  created() {
    // console.log('mixin...', this.name);
  },
  methods: {
    goLocation() {
      // https: //tdllly.fliggy.com/shop/view_shop.htm?spm=181.11197625.4974055.4.32aa4a0asDFG9k
      let openWin = window.open(
        "https://traveldetail.fliggy.com/item.htm?spm=181.11197625.4973973.3.32aa4a0a6c9NGu&id=650434138865"
      );
      openWin.opener = null;
    },
    goNavArea(anchorId) {
      let anchorElement = document.getElementById(anchorId);
      if (anchorElement) {
        anchorElement.scrollIntoView();
      }
    },
  }


}
