<template>
  <div class="languageWrap" :style="wrapStyle">
    <div class="txt" @click.stop="showPad">
      <span class="dIcon">
        <img :src="require('../assets/image/head/earth.png')" alt />
      </span>
      <span>{{title}}</span>
      <span class="drpArrow">
        <img :src="require('../assets/image/head/drpd.png')" alt />
      </span>
    </div>
    <ul class="drpWrap" v-show="padShowFlag">
      <li>
        <a class="linkWrap a1" @click.stop="itemHandle('/')">
          <img class="flag" :src="require('../assets/image/head/cn.png')" alt />
          <div class="txtWrap">
            <span>中文</span>
            <span>（中国）</span>
          </div>
        </a>
      </li>
      <li>
        <a class="linkWrap a2" @click.stop="itemHandle('/home_en')">
          <img :src="require('../assets/image/head/en.png')" alt />
          <div class="txtWrap">
            <span>English</span>
            <span>（USA）</span>
          </div>
        </a>
      </li>
    </ul>
  </div>
</template>

<script type="text/ecmascript-6">
export default {
  name: "m-language-bar",
  props: {
    bgcolor: {
      type: String,
      default: "#efefef"
    },
    title: {
      type: String,
      default: "CN"
    }
  },
  data() {
    return {
      padShowFlag: false
    };
  },
  computed: {
    wrapStyle() {
      let obj = {
        backgroundColor: this.bgcolor
      };
      return obj;
    }
  },
  mounted() {
    // 为了实现点击下拉框以外地方关闭下拉框
    document.addEventListener("click", this.hiddenClick);
  },
  methods: {
    hiddenClick(e) {
      this.padShowFlag = false;
    },
    itemHandle(url) {
      this.$router.push({ path: url });
    },
    showPad() {
      this.padShowFlag = !this.padShowFlag;
    }
  }
};
</script>

<style scoped lang="scss" rel="stylesheet/scss">
@import "src/assets/styles/variables.scss";
.languageWrap {
  position: absolute;
  top: 0;
  right: 0px;
  z-index: 1;
  background-color: #fff;
  // border-radius: 4px;
  // border: 1px solid $main-gray;
  > .txt {
    width: 84px;
    height: 33px;
    // background: $main-gray;
    padding-left: 8px;
    padding-right: 8px;
    color: $main-black;
    font-size: 18px;
    line-height: 33px;
    cursor: pointer;
    > .dIcon {
      display: inline-block;
      width: 18px;
      height: 100%;
      vertical-align: middle;
      margin-right: 8px;
      line-height: 2;
    }
    > .drpArrow {
      display: inline-block;
      width: 18px;
      height: 100%;
      vertical-align: middle;
      margin-left: 13px;
    }
  }
  > .drpWrap {
    position: absolute;
    border: 1px solid $main-gray;
    top: 43px;
    border-radius: 8px;
    > li {
      // width: 177px;
      // height: 40px;
      color: $main-black;
      background: $main-white;
      font-size: 18px;
      cursor: pointer;

      // border: 1px solid red;
      &:nth-of-type(1) {
        // padding-top: 4px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }
      &:nth-of-type(2) {
        // padding-top: 4px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
      .linkWrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 167px;
        height: 45px;
        line-height: 45px;
        padding-left: 15px;
        padding-right: 7px;

        &:hover {
          background: rgba(251, 185, 42, 0.2);
        }
        .flag {
          width: 27px;
          height: 22px;
          vertical-align: middle;
          margin-right: 9px;
        }
        .txtWrap {
          display: flex;
          justify-content: space-between;
          width: calc(100% - 37px);
        }
      }
      .linkWrap.a1 {
        // padding-top: 4px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }
      .linkWrap.a2 {
        // padding-top: 4px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
      a.active,
      a:focus,
      a:active,
      a,
      a:hover {
        color: $main-black;
      }

      // > span {
      //   display: inline-block;
      // }
    }
    &:before {
      content: "";
      position: absolute;
      background-image: url(~@/assets/image/head/dialog.png);
      background-repeat: no-repeat;
      top: -7px;
      left: 30px;
      width: 16px;
      height: 10px;
    }
  }
}
</style>

