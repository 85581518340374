import {
  createApp
} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import 'swiper/swiper-bundle.css';
import axios from 'axios';

import '../node_modules/swiper/swiper-bundle.css'; //swiper6.0样式路径
import Swiper, {
  Navigation,
  Autoplay,
  Thumbs
} from 'swiper'; //相关组件
Swiper.use([Navigation, Autoplay, Thumbs]);

const app = createApp(App);

app.config.globalProperties.$http = axios


//------style scope--------------------
import 'normalize.css/normalize.css'
import './assets/styles/base.scss'
import './assets/styles/mask.scss'

//------style scope-end-----------------



// router.afterEach(() => {
//   document.documentElement.scrollTop = 0;
// })
router.beforeEach((to, from, next) => {
  /* 修改页签title */
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
})

app.use(store).use(router).mount('#app')

// createApp(App).use(store).use(router).mount('#app')
