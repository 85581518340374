import {
  createRouter,
  createWebHashHistory,
  createWebHistory
} from 'vue-router'
import Home from '../views/Home.vue'
import HomeEn from '../views/Home_en.vue'
// import Notice from '../views/Notice.vue'


const routes = [{
    path: '/home_en',
    name: 'Home_en',
    component: HomeEn,
    meta: {
      title: 'home'
    }
  },
  {
    path: '/summary_en',
    name: 'Summary_en',
    component: () => import('../views/Summary_en.vue'),
    meta: {
      title: 'Tourism strategy'
    }
  },
  {
    path: '/play_en',
    name: 'Play_en',
    component: () => import('../views/Play_en.vue'),
    meta: {
      title: 'Tourism strategy'
    }
  },
  {
    path: '/stay_en',
    name: 'Stay_en',
    component: () => import('../views/Stay_en.vue'),
    redirect: '/stay_en/roomInfo',
    meta: {
      title: 'Tourism strategy'
    },
    children: [{
      path: '/stay_en/roomInfo',
      name: 'roomInfo',
      component: () => import('../views/StayTabsEn/RoomInfo.vue')
    }]
  },
  {
    path: '/buy_en',
    name: 'Buy_en',
    component: () => import('../views/Buy_en.vue'),
    redirect: '/buy_en/garageKits',
    meta: {
      title: 'Tourism strategy'
    },
    children: [{
      path: '/buy_en/garageKits',
      name: 'garageKits',
      component: () => import('../views/BuyTabEn/GarageKits.vue')
    }, {
      path: '/buy_en/ip_en',
      name: 'ip_en',
      component: () => import('../views/BuyTabEn/Ip.vue')
    }, {
      path: '/buy_en/health',
      name: 'health',
      component: () => import('../views/BuyTabEn/Health.vue')
    }, {
      path: '/buy_en/beauty',
      name: 'beauty',
      component: () => import('../views/BuyTabEn/Beauty.vue')
    }]
  },
  {
    path: '/food_en',
    name: 'Food_en',
    meta: {
      title: 'Tourism strategy'
    },
    component: () => import('../views/Food_en.vue'),
  },
  {
    path: '/video_en',
    name: 'video_en',
    meta: {
      title: 'VR and Video'
    },
    component: () => import('../views/Video_en.vue')
  },
  {
    path: '/panorama_en',
    name: 'panorama_en',
    meta: {
      title: 'VR and Video'
    },
    component: () => import('../views/Panorama_en.vue')
  },
  {
    path: '/company_en',
    name: 'company_en',
    meta: {
      title: 'About us'
    },
    component: () => import('../views/Company_en.vue')
  }, {
    path: '/service_en',
    name: 'service_en',
    component: () => import('../views/Service_en.vue'),
    redirect: '/service_en/traffic_en',
    meta: {
      title: 'Tourism services'
    },
    children: [{
      path: '/service_en/traffic_en',
      name: 'traffic_en',
      meta: {
        index: '0'
      },
      component: () => import('../views/ServiceTabsEn/Traffic_en.vue')
    }, {
      path: '/service_en/guide_en',
      name: 'guide_en',
      meta: {
        index: '1'
      },
      component: () => import('../views/ServiceTabsEn/Guide_en.vue')
    }, {
      path: '/service_en/notice_en',
      name: 'notice_en',
      meta: {
        index: '3'
      },
      component: () => import('../views/ServiceTabsEn/Notice_en.vue')
    }]
  }, {
    path: '/route_en',
    name: 'route_en',
    meta: {
      title: 'Tourism strategy'
    },
    component: () => import('../views/Route_en.vue')
  },
  {
    path: '/ticket_en',
    name: 'Ticket_en',
    meta: {
      title: 'Tourism strategy'
    },
    component: () => import('../views/Ticket_en.vue')
  },
  {
    path: '/food_en',
    name: 'Food_en',
    component: () => import('../views/Food_en.vue'),
    redirect: "/food_en/xiCan_en",
    meta: {
      title: 'Tourism strategy'
    },
    children: [{
      path: '/food_en/xiCan_en',
      name: 'xiCan_en',
      component: () => import('../views/FoodTabsEn/xiCan_en.vue')
    }, {
      path: '/food_en/zhongCan_en',
      name: 'zhongCan_en',
      component: () => import('../views/FoodTabsEn/zhongCan_en.vue')
    }, {
      path: '/food_en/hongBei_en',
      name: 'hongBei_en',
      component: () => import('../views/FoodTabsEn/hongBei_en.vue')
    }]
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: '铁定溜溜'
    }
  }, {
    path: '/summary',
    name: 'Summary',
    component: () => import( /* webpackChunkName: "about" */ '../views/Summary.vue'),
    meta: {
      title: '游玩攻略'
    }
  }, {
    path: '/play',
    name: 'Play',
    component: () => import('../views/Play.vue'),
    meta: {
      title: '游玩攻略'
    }
  }, {
    path: '/stay',
    name: 'Stay',
    component: () => import('../views/Stay.vue'),
    meta: {
      title: '游玩攻略'
    }
  }, {
    path: '/buy',
    name: 'Buy',
    component: () => import('../views/Buy.vue'),
    meta: {
      title: '游玩攻略'
    }
  },
  {
    path: '/food',
    name: 'Food',
    component: () => import('../views/Food.vue'),
    meta: {
      title: '游玩攻略'
    },
    redirect: "/food/xiCan",
    children: [{
      path: '/food/xiCan',
      name: 'xiCan',
      component: () => import('../views/FoodTabs/xiCan.vue')
    }, {
      path: '/food/zhongCan',
      name: 'zhongCan',
      component: () => import('../views/FoodTabs/zhongCan.vue')
    }, {
      path: '/food/hongBei',
      name: 'hongBei',
      component: () => import('../views/FoodTabs/hongBei.vue')
    }]
  },
  {
    path: '/service',
    name: 'service',
    component: () => import('../views/Service.vue'),
    meta: {
      title: '游客服务'
    },
    children: [{
      path: '/service/traffic',
      name: 'traffic',
      meta: {
        index: '0'
      },
      component: () => import('../views/ServiceTabs/Traffic.vue')
    }, {
      path: '/service/guide',
      name: 'guide',
      meta: {
        index: '1'
      },
      component: () => import('../views/ServiceTabs/Guide.vue')
    }, {
      path: '/service/feedback',
      name: 'feedback',
      meta: {
        index: '2'
      },
      component: () => import('../views/ServiceTabs/Feedback.vue')
    }, {
      path: '/service/notice',
      name: 'notice',
      meta: {
        index: '3'
      },
      component: () => import('../views/ServiceTabs/Notice.vue')
    }, {
      path: '/service/question',
      name: 'question',
      meta: {
        index: '4'
      },
      component: () => import('../views/ServiceTabs/Question.vue')
    }]
  }, {
    path: '/ticketRoute',
    name: 'ticketRoute',
    component: () => import('../views/TicketRoute.vue'),
    meta: {
      title: '游玩攻略'
    },
    redirect: "/ticketRoute/ticket",
    children: [{
      path: '/ticketRoute/ticket',
      name: 'ticket',
      component: () => import('../views/TicketRouteTabs/Ticket.vue')
    }, {
      path: '/ticketRoute/route',
      name: 'route',
      component: () => import('../views/TicketRouteTabs/Route.vue')
    }]
  }, {
    path: '/contactUs',
    name: 'contactUs',
    component: () => import('../views/ContactUs.vue'),
    meta: {
      title: '关于我们'
    }
  },
  {
    path: '/company',
    name: 'company',
    component: () => import('../views/Company.vue'),
    meta: {
      title: '关于我们'
    }
  }, {
    path: '/panorama',
    name: 'panorama',
    component: () => import('../views/Panorama.vue'),
    meta: {
      title: 'VR/视频'
    }
  },
  {
    path: '/video',
    name: 'video',
    component: () => import('../views/Video.vue'),
    meta: {
      title: 'VR/视频'
    }
  }, {
    path: '/eventInfo',
    name: 'eventInfo',
    redirect: "/eventInfo/announcement",
    component: () => import('../views/EventInfo.vue'),
    meta: {
      title: '活动资讯'
    },
    children: [{
      path: '/eventInfo/announcement',
      name: 'announcement',
      component: () => import('../views/EventInfos/Announcement.vue')
    }, {
      path: '/eventInfo/discount',
      name: 'discount',
      component: () => import('../views/EventInfos/Discount.vue')
    }, {
      path: '/eventInfo/travel',
      name: 'travel',
      component: () => import('../views/EventInfos/Travel.vue')
    }]
  },
  {
    path: '/eventInfoDetail',
    name: 'eventInfoDetail',
    component: () => import('../views/EventInfoDetail.vue'),
    meta: {
      title: '活动资讯'
    },
  }
]


const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  // 打包时使用
  history: createWebHashHistory(),
  routes
})



export default router
