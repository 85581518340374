<template>
  <div class="footerWrap">
    <div class="content">
      <ul>
        <li class="item">
          <div class="contentWrap">
            <img :src="require('../assets/image/head_en/call.png')" alt />
            <div class="layoutRight">
              <span class="title">Give us a Call</span>
              <span class="detail">400-661-5666</span>
              <span class="detail">(am 8:00————pm 17:30)</span>
            </div>
          </div>
        </li>
        <li class="item">
          <div class="contentWrap">
            <img :src="require('../assets/image/head_en/location.png')" alt />
            <div class="layoutRight">
              <span class="title">Visit our Location</span>
              <span class="detail">Xia Shan Tou Cun,Dajing Town</span>
              <span class="detail">Yueqing City,Zhejiang Province</span>
            </div>
          </div>
        </li>
        <li class="item">
          <div class="contentWrap">
            <img :src="require('../assets/image/head_en/bus.png')" alt />
            <div class="layoutRight">
              <span class="title">How to take the bus</span>
              <span class="detail">Take No.512 to the Fifth</span>
              <span class="detail">People's hospital</span>
            </div>
          </div>
        </li>
      </ul>
      <div class="layoutBottom">
        <div class="layoutLeft">
          <span>Copyright@2019 Copyright Yueqing manfangshi Cultural Tourism Co.,Ltd</span>
          <span>Zhejiang ICP prepared No. 123456789</span>
          <span>Technical support: Sichuan lintrondata technology co., LTD</span>
        </div>
        <div class="layoutRight">
          <div class="group">
            <div class="bg">
              <img :src="require('../assets/image/head/code1.png')" alt />
            </div>
            <span class="g1">WeChat Subscription</span>
          </div>
          <div class="group">
            <div class="bg">
              <img :src="require('../assets/image/head/code2.png')" alt />
            </div>
            <span>Alipay applet</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
export default {
  name: "m-footer-bar"
};
</script>

<style scoped lang="scss" rel="stylesheet/scss">
@import "src/assets/styles/variables.scss";
.footerWrap {
  background: $main-blue;
  .content {
    width: 1400px;
    height: 300px;
    margin: 0 auto;
    > ul {
      border-bottom: 1px solid $main-gray;
      padding-top: 10px;
      padding-bottom: 10px;
      > .item {
        display: inline-block;
        box-sizing: border-box;
        > .contentWrap {
          height: 106px;
          border-right: 1px solid $main-white;
          box-sizing: border-box;
          > img {
            vertical-align: top;
          }
          > .layoutRight {
            display: inline-block;
            vertical-align: text-top;
            > span {
              display: block;
              font-size: 20px;
            }
            .title {
              color: $main-black;
              // font-weight: bold;
            }
            .detail {
              color: $main-white;
            }
          }
        }
      }
      > .item:nth-of-type(1) {
        width: 30%;
        > .contentWrap {
          width: 100%;
          padding: 14px 0 12px 2px;
          .layoutRight {
            // padding-top: 3px;
            .title {
              margin-bottom: 13px;
            }
          }
        }
      }
      > .item:nth-of-type(2) {
        width: 40%;
        > .contentWrap {
          width: 100%;
          padding: 14px 0 12px 107px;
          .layoutRight {
            // padding-top: 5px;
            margin-left: 3px;
            .title {
              margin-bottom: 13px;
            }
          }
        }
      }
      > .item:nth-of-type(3) {
        width: 29%;
        > .contentWrap {
          width: 100%;
          padding: 14px 0 12px 70px;
          border-right: 0;
          .layoutRight {
            // padding-top: 3px;
            margin-left: 10px;
            .title {
              margin-bottom: 16px;
            }
          }
        }
      }
    }
    > .layoutBottom {
      color: $main-white;
      > .layoutLeft {
        display: inline-block;
        width: 61%;
        padding-left: 22px;
        box-sizing: border-box;
        vertical-align: top;
        margin-top: 26px;
        > span {
          display: block;
          margin-bottom: 22px;
          font-size: 20px;
        }
      }
      > .layoutRight {
        display: inline-block;
        width: 38%;
        padding-left: 65px;
        box-sizing: border-box;
        vertical-align: top;
        margin-top: 17px;
        .group {
          display: inline-block;
          text-align: center;
          .bg {
            width: 106px;
            height: 106px;
            padding-top: 3px;
            background: $main-white;
            display: table-cell;
            vertical-align: middle;
            > img {
              width: 92px;
              height: 92px;
            }
          }
          > span {
            margin-top: 5px;
            display: block;
            font-size: 16px;
          }
          > span.g1 {
            margin-left: -22px;
          }
        }
        .group:nth-of-type(1) {
          margin-right: 54px;
        }
      }
    }
  }
}
// .footerWrap {
//   background: $main-blue;
//   .content {
//     width: 1400px;
//     height: 300px;
//     margin: 0 auto;
//     > ul {
//       border-bottom: 1px solid $main-gray;
//       padding-top: 10px;
//       padding-bottom: 10px;
//       > .item {
//         display: inline-block;
//         box-sizing: border-box;
//         > .contentWrap {
//           height: 106px;
//           border-right: 1px solid $main-white;
//           box-sizing: border-box;
//           > img {
//             vertical-align: top;
//           }
//           > .layoutRight {
//             display: inline-block;
//             vertical-align: text-top;
//             > span {
//               display: block;
//               font-size: 20px;
//             }
//             .title {
//               color: $main-black;
//               // font-weight: bold;
//             }
//             .detail {
//               color: $main-white;
//             }
//           }
//         }
//       }
//       > .item:nth-of-type(1) {
//         > .contentWrap {
//           width: 402px;
//           padding: 14px 0 12px 2px;
//           .layoutRight {
//             // padding-top: 3px;
//             .title {
//               margin-bottom: 13px;
//             }
//           }
//         }
//       }
//       > .item:nth-of-type(2) {
//         > .contentWrap {
//           width: 586px;
//           padding: 14px 0 12px 107px;
//           .layoutRight {
//             // padding-top: 5px;
//             margin-left: 3px;
//             .title {
//               margin-bottom: 13px;
//             }
//           }
//         }
//       }
//       > .item:nth-of-type(3) {
//         > .contentWrap {
//           width: 409px;
//           padding: 14px 0 12px 70px;
//           border-right: 0;
//           .layoutRight {
//             // padding-top: 3px;
//             margin-left: 10px;
//             .title {
//               margin-bottom: 16px;
//             }
//           }
//         }
//       }
//     }
//     > .layoutBottom {
//       color: $main-white;
//       > .layoutLeft {
//         display: inline-block;
//         width: 990px;
//         padding-left: 22px;
//         box-sizing: border-box;
//         vertical-align: top;
//         margin-top: 26px;
//         > span {
//           display: block;
//           margin-bottom: 22px;
//           font-size: 20px;
//         }
//       }
//       > .layoutRight {
//         display: inline-block;
//         width: 409px;
//         padding-left: 65px;
//         box-sizing: border-box;
//         vertical-align: top;
//         margin-top: 17px;
//         .group {
//           display: inline-block;
//           text-align: center;
//           .bg {
//             width: 106px;
//             height: 106px;
//             padding-top: 3px;
//             background: $main-white;
//             display: table-cell;
//             vertical-align: middle;
//             > img {
//               width: 92px;
//               height: 92px;
//             }
//           }
//           > span {
//             margin-top: 5px;
//             display: block;
//             font-size: 16px;
//           }
//           > span.g1 {
//             margin-left: -22px;
//           }
//         }
//         .group:nth-of-type(1) {
//           margin-right: 54px;
//         }
//       }
//     }
//   }
// }

@media (min-width: $screen-lg-min) and (max-width: $screen-sp-min) {
  .footerWrap {
    background: $main-blue;
    .content {
      width: 1100px;
      > ul {
        > .item:nth-of-type(1) {
          width: 30%;
          > .contentWrap {
            width: 100%;
          }
        }
        > .item:nth-of-type(2) {
          width: 40%;
          > .contentWrap {
            width: 100%;
            padding: 14px 0 12px 35px;
          }
        }
        > .item:nth-of-type(3) {
          width: 29%;
          > .contentWrap {
            width: 100%;
            padding: 14px 0 12px 6px;
          }
        }
      }
      > .layoutBottom {
        > .layoutLeft {
          width: 61%;
          padding-left: 7px;
        }
        > .layoutRight {
          width: 38%;
        }
      }
    }
  }
}
</style>
