<template>
  <div class="homePageWrap en">
    <m-layout-bar>
      <div class="homeWrap en">
        <div class="bannerWrap">
          <!-- 轮播 {delay:4000}-->
          <swiper
            class="swiper-container"
            slidesPerView="1"
            :pagination="{ clickable: true }"
            :autoplay="false"
            :loop="false"
          >
            <swiper-slide class="swiper-slide" v-for="(item, index) in banners" :key="index">
              <img style="width:100%; height: 793px;" :src="baseUrl + item" alt />
            </swiper-slide>
          </swiper>
          <!-- 轮播完毕 -->
          <!-- <img :src="require('../assets/image/home/banner.png')" alt /> -->
        </div>

        <!-- 1. area1 Dream Farmland-->
        <div class="dreamlandWrap bigGroup">
          <div class="content">
            <div class="titleWrap">
              <span class="title">Welcome to Dream Farmland</span>
              <span class="subtitle">
                If you are coming to Dream Farmland for the first time,
                <br />you can choose the following three ways to get to know us. Select and click for details
              </span>
            </div>
            <div class="contentWrap">
              <router-link class="group left ifocus_up" tag="div" to>
                <div class="content">
                  <div class="icon">
                    <img :src="require('../assets/image/home_en/g1.png')" alt />
                  </div>
                  <span class="title">
                    Introduction to
                    <br />amusement park
                  </span>
                  <span class="detail">
                    One mainly takes Dendrobium candidum
                    as the core and gathers theme amusement,
                    pastoral leisure and tourism
                    It is a pastoral complex project
                    integrating vacation and popular science education
                  </span>
                  <router-link class="btn ifocus_shadow" to>Learn more</router-link>
                </div>
              </router-link>
              <router-link class="group mid ifocus_up" tag="div" to>
                <div class="content">
                  <div class="icon">
                    <img :src="require('../assets/image/home_en/g2.png')" alt />
                  </div>
                  <span class="title">Park Guide</span>
                  <span class="detail">
                    Here is a map of the park.
                    You can learn about all our amusement projects
                    through the map
                  </span>
                  <router-link class="btn ifocus_shadow" to>Learn more</router-link>
                </div>
              </router-link>
              <router-link class="group right ifocus_up" tag="div" to>
                <div class="content">
                  <div class="icon">
                    <img :src="require('../assets/image/home_en/g3.png')" alt />
                  </div>
                  <span class="title">Notice to Visitors</span>
                  <span class="detail">
                    Here are the instructions for visiting the park.
                    I hope you can read them carefully
                    and help you play
                  </span>
                  <router-link class="btn ifocus_shadow" to>Learn more</router-link>
                </div>
              </router-link>
            </div>
          </div>
        </div>

        <!-- 2、how to play -->
        <div
          class="howToPlayWrap bigGroup"
          :style="{background:'url('+hiBgImgUrl+')',backgroundSize:'100% 100%'}"
        >
          <div class="content">
            <div class="titleWrap">
              <span class="title">How to play in Dream Farmland</span>
              <span
                class="subtitle"
              >You can find food, accommodation, entertainment and shopping here</span>
            </div>
            <div class="contentWrap">
              <router-link class="group g1 g ifocus_up" to="/food_en">
                <img :src="require('../assets/image/home_en/hi_1.png')" alt />
                <div class="detail">
                  <span>Eat leisurely</span>
                  <img class="icon" :src="require('../assets/image/home_en/hiIcon1.png')" alt />
                </div>
              </router-link>
              <router-link class="group g2 g ifocus_up" to="/stay_en">
                <img :src="require('../assets/image/home_en/hi_2.png')" alt />
                <div class="detail">
                  <span>Live leisurely</span>
                  <img class="icon" :src="require('../assets/image/home_en/hiIcon2.png')" alt />
                </div>
              </router-link>
              <router-link class="group g3 g ifocus_up" to="/play_en">
                <img :src="require('../assets/image/home_en/hi_3.png')" alt />
                <div class="detail">
                  <span>Play leisurely</span>
                  <img class="icon" :src="require('../assets/image/home_en/hiIcon3.png')" alt />
                </div>
              </router-link>
              <router-link class="group g4 g ifocus_up" to="/buy_en">
                <img :src="require('../assets/image/home_en/hi_4.png')" alt />
                <div class="detail">
                  <span>Buy leisurely</span>
                  <img class="icon" :src="require('../assets/image/home_en/hiIcon4.png')" alt />
                </div>
              </router-link>
            </div>
          </div>
        </div>

        <!-- 3.vrWrap -->
        <div class="vrWrap bigGroup">
          <div class="content">
            <div class="titleWrap">
              <span class="title">Welcome to Dream Farmland</span>
              <span class="subtitle">You can choose online VR or video to learn about us</span>
            </div>
            <div class="contentWrap">
              <div
                class="group vr shadowBox maskWrap size toShow ifocus_up"
                @click="watchVR(vrInfo.link,$event)"
              >
                <img :src="baseUrl + vrInfo.cover" alt />
                <div class="titleWrap">
                  <span class="title textline1">{{vrInfo.title}}</span>
                  <span class="subTitle textline1">{{vrInfo.info}}</span>
                </div>
                <router-link class="otherWrap textline1" data-flag="vr" to="/panorama_en">
                  <!-- <div class="otherWrap"> -->
                  <img data-flag="vr" :src="require('../assets/image/home/vr_icon_2.png')" alt />
                  Learn more
                  <!-- </div> -->
                </router-link>
                <div class="decorateWrap">
                  <div>
                    <img :src="require('../assets/image/home/vr_icon_2.png')" alt />
                    VR
                  </div>
                </div>
              </div>
              <div
                class="group video shadowBox maskWrap size toShow ifocus_up"
                @click="watchVideo(videoInfo.link,$event)"
              >
                <img :src="baseUrl + videoInfo.cover" alt @click="watchVideo(videoInfo.link)" />
                <div class="titleWrap">
                  <span class="title textline1">{{videoInfo.title}}</span>
                  <span class="subTitle textline1">{{videoInfo.info}}</span>
                </div>
                <router-link class="otherWrap textline1" data-flag="video" to="/video_en">
                  <!-- <div class="otherWrap"> -->
                  <img data-flag="video" :src="require('../assets/image/home/vr_icon_3.png')" alt />
                  Learn more
                  <!-- </div> -->
                </router-link>
                <div class="decorateWrap textline1">
                  <div>
                    <img :src="require('../assets/image/home/vr_icon_3.png')" alt />
                    Video
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 4 -->
        <div class="routeWrap bigGroup">
          <div class="content">
            <div class="titleWrap">
              <span class="title">Recommended route</span>
              <span class="subtitle">
                We have a variety of tourist routes.
                <br />Whether you travel with your family or take a personal vacation, we will provide you with high-quality services.
              </span>
            </div>
            <div class="contentWrap">
              <div class="tabsWrap">
                <ul class="tabs">
                  <li class="item" :class="{'active':cur=='0'}" data-index="0" @click="clickHandle">
                    <img :src="require('../assets/image/home_en/tab1.png')" alt />
                    <span>Leisure countryside</span>
                  </li>
                  <li class="item" :class="{'active':cur=='1'}" data-index="1" @click="clickHandle">
                    <img :src="require('../assets/image/home_en/tab2.png')" alt />
                    <span>Parent child paradise</span>
                  </li>
                  <li class="item" :class="{'active':cur=='2'}" data-index="2" @click="clickHandle">
                    <img :src="require('../assets/image/home_en/tab3.png')" alt />
                    <span>Health resort</span>
                  </li>
                </ul>
                <div class="tabContent">
                  <div class="tabDetailWrap" :class="{'active':cur=='0'}" v-show="cur=='0'">
                    <span>
                      Parent child park is an activity attended by parents or families with their babies. It is a place for communication and activities between
                      parent-child groups. It is not only a paradise for children's growth and learning, but also a classroom for parents. It gives parents specific,
                      professional and systematic guidance in the ways and methods of early education. On the one hand, it can enhance the feelings of parents
                      and children, on the other hand, it can make parents know more about the age characteristics of their children, so as to carry out targeted
                      education.
                    </span>
                    <router-link to="/route_en" class="more ifocus_shadow_grayLight">Learn more</router-link>
                  </div>
                  <div class="tabDetailWrap" v-show="cur=='1'">
                    <span>
                      Parent child park is an activity attended by parents or families with their babies. It is a place for communication and activities between
                      parent-child groups.
                    </span>
                    <router-link to="/route_en" class="more ifocus_shadow_grayLight">Learn more</router-link>
                  </div>
                  <div class="tabDetailWrap" v-show="cur=='2'">
                    <span>Parent child park is an activity attended by parents or families with their babies.</span>
                    <router-link to="/route_en" class="more ifocus_shadow_grayLight">Learn more</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </m-layout-bar>
  </div>
</template>

<script type="text/ecmascript-6">
import Layout from "@/components/LayoutBar_en";
import SwiperCore, { Navigation, Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
// import style (>= Swiper 6.x)
import "swiper/swiper-bundle.css";
SwiperCore.use([Navigation, Pagination, Autoplay]);

import axios from "axios";
import config from "@/utils/config";

export default {
  components: {
    [Layout.name]: Layout,
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      showFlag: true,
      hiBgImgUrl: require("../assets/image/home_en/hi_bg.png"),
      // banners: ["banner.png", "banner.png", "banner.png"],
      cur: 0,
      banners: [],
      dataInfo: new Array(),
      vrInfo: {},
      videoInfo: {},
      activeInfo: new Array()
    };
  },
  computed: {
    baseUrl() {
      return config.webBaseUrl;
    }
  },
  mounted() {
    // homeBannerUrl
    this.getData(config.homeBannerUrl_en).then(res => {
      let obj = {};
      if (res.length > 0) {
        let obj = res[0];
        if (Object.keys(obj).length != 0) {
          let list = obj.photos.split(",");
          if (list.length > 0) {
            this.banners = list;
          }
          // console.log("banner 返回：", res, list);
        }
      }
    });
    this.getData(config.vrUrl_en).then(res => {
      if (res.length > 0) {
        this.vrInfo = res[0];
      }
      console.log("vr 返回：", res);
    });
    this.getData(config.videoUrl_en).then(res => {
      if (res.length > 0) {
        this.videoInfo = res[0];
      }
      // console.log("videoInfo 返回：", res);
    });
  },
  methods: {
    watchVR(link, e) {
      // console.log("e.target:", e.target, e.currentTarget, "vrlink:", link);
      if (
        e.target.getAttributeNode("data-flag") != null &&
        e.target.getAttributeNode("data-flag").value == "vr"
      )
        return;
      if (link == "") return;
      let openWin = window.open(link);
      openWin.opener = null;
    },
    watchVideo(link, e) {
      // console.log("videolink:", link);
      // console.log("e.target:", e.target, e.currentTarget, "vrlink:", link);
      if (
        e.target.getAttributeNode("data-flag") != null &&
        e.target.getAttributeNode("data-flag").value == "video"
      )
        return;
      if (link == "") return;
      let openWin = window.open(this.baseUrl + link);
      openWin.opener = null;
    },
    async getData(url) {
      try {
        let res = await axios.get(url);
        if (res.data.code == "0" && res.data.data instanceof Array) {
          if (res.data.data.length > 0) {
            let tempData = JSON.parse(JSON.stringify(res.data.data));
            this.dataInfo = tempData;
            // console.log("res.data.data:", this.dataInfo);
            return tempData;
          }
        }
        return null;
      } catch (e) {
        console.log("res err:", e);
      }
    },
    clickHandle(event) {
      if (
        !event &&
        !event.currentTarget &&
        !event.currentTarget.getAttributeNode("data-index")
      ) {
        return;
      }
      // console.log("data-index", event.target.getAttributeNode("data-index"));
      let index = event.currentTarget.getAttributeNode("data-index").value;

      if (!index) {
        this.cur = -1;
      } else {
        this.cur = parseInt(index);
      }
    }
  }
};
</script>

<style scoped lang="scss" rel="stylesheet/scss">
@import "src/assets/styles/variables.scss";

// 公用--------------------------------
.bigGroup {
  text-align: center;
  > .content {
    width: 1400px;
    padding: 0 23px;
    margin: 70px auto 0;

    > .titleWrap {
      > .title {
        display: block;
        font-size: 36px;
        font-weight: bold;
        // color: $main-red;
      }
      > .subtitle {
        display: block;
        font-size: 18px;
        // color: $font-grayDarkDD;
      }
    }
    > .contentWrap {
      display: flex;
      justify-content: space-between;
      margin-top: 113px;
    }
  }
}

// 公用 end--------------------------------
.homePageWrap {
  .homeWrap {
    .bannerWrap {
      width: 100%;
      // height: 800px;
      > img {
        width: 100%;
        height: 100%;
      }
    }

    // 1
    .dreamlandWrap {
      text-align: center;

      > .content {
        // width: 1400px;
        // padding: 0 23px;
        // margin: 70px auto;

        > .titleWrap {
          > .title {
            color: $main-red;
          }
          > .subtitle {
            color: $font-grayDarkDD;
          }
        }
        > .contentWrap {
          margin-top: 113px;
          > .group {
            flex: none;
            width: 32%;
            > .content {
              position: relative;
              // width: 397px;

              height: 375px;
              border: 1px solid $main-yellow;
              color: $main-white;
              background: $main-yellow;
              border-radius: 10px;
              > .icon {
                position: absolute;
                width: 113px;
                height: 113px;
                // line-height: 9;
                top: -57px;
                left: 0;
                right: 0;
                margin: 0 auto;
                border-radius: 50%;
                border: 2px solid $main-white;
                background: $main-yellow;
                > img {
                  position: absolute;
                  top: 0;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  margin: auto;
                  // top: 50%;
                  // transform: translateY(-50%);
                }
              }
              > .title {
                display: block;
                height: 54px;

                font-size: 24px;
                font-weight: bold;
                margin-top: 88px;
              }
              > .detail {
                display: block;
                font-size: 16px;
                margin-top: 23px;
                padding-left: 28px;
                padding-right: 23px;
              }
              > .btn {
                position: absolute;
                bottom: 58px;
                display: block;
                left: 0;
                right: 0;
                width: 103px;
                font-size: 20px;
                font-style: italic;
                margin-top: 40px;
                margin: 40px auto 0 auto;
                border-bottom: 2px solid $main-white;
              }
              > a,
              > a:hover,
              > a:active,
              > a:focus {
                color: $main-white;
              }
            }
          }
          > .group:nth-of-type(2) {
            > .content {
              background: $main-blue;
              border: 1px solid $main-blue;
              > .icon {
                background: $main-blue;
              }
            }
          }
          > .group:nth-of-type(3) {
            > .content {
              background: $main-purple;
              border: 1px solid $main-purple;
              > .icon {
                background: $main-purple;
              }
            }
          }
        }
      }
    }

    // 2
    .howToPlayWrap {
      height: 762px;
      > .content {
        padding-top: 70px;
        > .titleWrap {
          > .title {
            color: $main-white;
          }
          > .subtitle {
            margin-top: 25px;
            font-size: 16px;
            color: $main-white;
          }
        }
        > .contentWrap {
          > .group {
            flex: none;
            width: 21%;
            position: relative;
            display: block;
            // width: 295px;
            height: 377px;
            border-radius: 10px;
            transition: all 0.1s ease-in-out;
            > img {
              width: 100%;
              // width: 295px;
              height: 255px;
              display: block;
            }
            > .detail {
              width: 100%;
              height: 122px;
              padding-top: 20px;
              background: $main-white;
              color: $font-grayDarkDD;
              text-align: center;
              box-sizing: border-box;
              border-bottom-left-radius: 10px;
              border-bottom-right-radius: 10px;
              > span {
                display: block;

                font-size: 24px;
                font-weight: bold;
                // color: $main-white;
                margin-bottom: 2px;
              }
              // &:hover {
              //   background: $main-red;
              // }
              // > img {
              // }
            }
          }
          > .group:nth-of-type(1),
          > .group:nth-of-type(4) {
            margin-top: -136px;
          }
          > .group:after {
            content: "";
            position: absolute;
            width: 100%;
            height: 255px;
            top: 0;
            left: 0;
            background-image: url(~@/assets/image/home_en/iconPlus.png);
            background-position: 50% 50%;
            background-repeat: no-repeat;
            background-color: rgba(0, 0, 0, 0.6);
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            display: none;
          }
          > .group.g:before {
            color: $main-white;
            font-size: 24px;
            font-weight: bold;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 122px;
            line-height: 70px;
            background-color: $main-red;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            background-position: 50% 109%;
            background-repeat: no-repeat;
            display: none;
          }
          > .group.g1:before {
            content: "Eat leisurely";
            background-image: url(~@/assets/image/home_en/hiIconh1.png);
          }
          > .group.g2:before {
            content: "Live leisurely";
            background-image: url(~@/assets/image/home_en/hiIconh2.png);
          }
          > .group.g3:before {
            content: "Play leisurely";
            background-image: url(~@/assets/image/home_en/hiIconh3.png);
          }
          > .group.g4:before {
            content: "Buy leisurely";
            background-image: url(~@/assets/image/home_en/hiIconh4.png);
          }
          > .group:hover:after,
          .group:hover:before {
            display: block;
          }
        }
      }
    }

    // 3
    .vrWrap {
      .content {
        padding-top: 70px;
        > .titleWrap {
          > .title {
            color: $main-red;
          }
          > .subtitle {
            margin-top: 25px;
            font-size: 16px;
            color: $font-grayDarkDD;
          }
        }
        > .contentWrap {
          display: flex;
          justify-content: space-between;
          .group {
            position: relative;
            flex: none;
            width: 48%;
            // width: 640px;
            border-radius: 10px;
            > img {
              width: 100%;
              height: 378px;
              display: block;
            }
            > .titleWrap {
              color: $font-grayDarkD;
              text-align: center;
              padding-top: 15px;
              padding-bottom: 19px;
              height: 70px;
              box-sizing: border-box;

              > .title {
                display: block;
                font-size: 19px;
                font-weight: 700;
                margin-bottom: 11px;
              }
              > .subTitle {
                display: block;
                font-size: 13px;
                padding-left: 20px;
                padding-right: 20px;
              }
            }
            > .otherWrap {
              display: block;
              height: 71px;
              color: $main-white;
              font-size: 19px;
              background: $main-greenGrass;
              border-bottom-left-radius: 10px;
              border-bottom-right-radius: 10px;
              padding: 25px 0px;
              text-align: center;
              box-sizing: border-box;
              > img {
                margin-right: 16px;
                vertical-align: top;
              }
            }
            &.vr {
              > .otherWrap {
                &:hover {
                  background: $main-green;
                }
              }
            }
            &.video {
              > .otherWrap {
                &:hover {
                  background: $main-blueDark;
                }
              }
            }
            > .decorateWrap {
              position: absolute;
              width: 147px;
              height: 50px;
              line-height: 50px;
              color: $main-white;
              font-size: 12px;
              background: $main-greenGrass;
              border-bottom-right-radius: 50px;
              border-top-left-radius: 8px;
              top: 0px;
              left: 0px;
              img {
                // margin-left: 23px;
                margin-right: 4px;
                vertical-align: middle;
              }
            }
          }

          .group.video {
            > .otherWrap,
            > .decorateWrap {
              background: $main-blue;
            }
          }
        }
      }
    }

    // 4
    .routeWrap {
      background: $bg-orange;
      > .content {
        padding-top: 50px;
        padding-bottom: 100px;
        > .titleWrap {
          > .title {
            color: $main-white;
          }
          > .subtitle {
            margin-top: 25px;
            font-size: 16px;
            color: $main-white;
          }
        }
        > .contentWrap {
          // width: 1216px;
          height: 384px;
          border-radius: 10px;
          background: $main-white;
          margin: 30px auto 0 auto;
          > .tabsWrap {
            width: 100%;
            padding-left: 60px;
            padding-right: 51px;
            padding-bottom: 25px;
            > .tabs {
              display: flex;
              justify-content: space-between;
              height: 143px;
              margin-top: 30px;
              > .item {
                flex: 1;
                height: 143px;
                // display: inline-block;
                // background: red;
                margin-right: 1px;
                text-align: center;
                transition: all 0.4s ease-in;
                opacity: 0.6;
                > img {
                  width: 96px;
                  height: 96px;
                  display: block;
                  margin: 0 auto;
                }
                > span {
                  font-size: 22px;
                  color: $font-grayDarkDD;
                }
              }
              > .item.active {
                opacity: 1;
                transform: scale(1.3);
              }
            }
            > .tabContent {
              height: 184px;
              padding-top: 43px;
              transition: all 0.4s ease-in;
              box-sizing: border-box;
              > .tabDetailWrap {
                position: relative;
                widows: 1107px;
                height: 141px;
                text-align: left;

                // &.active {
                //   color: red;
                //   opacity: 0.8;
                // }
                > .more {
                  position: absolute;
                  right: 0;
                  bottom: 0;
                  display: block;
                  width: 83px;
                  height: 18px;
                  border-bottom: 1px solid $font-grayDarkDD;
                  font-style: italic;
                }
                > a,
                > a:hover,
                > a:active,
                > a:focus {
                  color: $font-grayDarkDD;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: $screen-lg-min) and (max-width: $screen-sp-min) {
  .bigGroup {
    text-align: center;
    > .content {
      width: 1100px;
    }
  }

  .homePageWrap {
    .homeWrap {
      .howToPlayWrap {
        > .content {
          > .contentWrap {
            > .group {
              flex: none;
              width: 24%;
            }
          }
        }
      }
    }
  }
}
</style>
