const config = {
  weatherUrl: "http://111.9.253.7:8107/weatherkit/weatherjson/yueqing",
  webBaseUrl: "http://www.chinatdll.com",
  // webBaseUrl: "http://tdll.zsytit.com",
  // webBaseUrl: "http://223.95.119.193:8081",
  summaryUrl: "/api/web/introduce/detail",
  stayUrl: "/api/web/hotel/list",
  buyUrlShouBanLi: "/api/web/goods/list?class_type=伴手礼",
  buyUrlIP: "/api/web/goods/list?class_type=IP萌物",
  buyUrlKangYang: "/api/web/goods/list?class_type=康养佳品",
  buyUrlMeiYan: "/api/web/goods/list?class_type=养颜美物",
  vrUrl: "/api/web/vr/list?page=1",
  videoUrl: "/api/web/video/list?page=1",
  eventAnnouceUrl: "/api/web/information/list?page=1&class_type=通知公告",
  eventDiscountUrl: "/api/web/information/list?page=1&class_type=优惠活动",
  eventTravelUrl: "/api/web/information/list?page=1&class_type=旅游资讯",
  eventDetailUrl: "/api/web/information/detail",
  serviceTraffic: "/api/web/route/detail",
  serviceNotice: "/api/web/notice/list",
  serviceQuestion: "/api/web/questions/list",
  serviceFeedback: "/api/web/feedback/edit?type=add",
  companyUrl: "/api/web/recruit/list",
  foodXiCanUrl: "/api/web/restaurant/list?class_type=西餐",
  foodZhongCanUrl: "/api/web/restaurant/list?class_type=中餐",
  foodHongBeiUrl: "/api/web/restaurant/list?class_type=烘焙点心",
  homeBannerUrl: "/api/web/indexbanner/detail",
  ticketUrl: "/api/web/ticketprice/detail",
  summaryUrl_en: "/api/weben/introduce/detail",
  stayUrl_en: "/api/weben/hotel/list",
  foodXiCanUrl_en: "/api/weben/restaurant/list?class_type=Western-style food",
  foodZhongCanUrl_en: "/api/weben/restaurant/list?class_type=Chinese food",
  foodHongBeiUrl_en: "/api/weben/restaurant/list?class_type=Baked dessert",
  buyUrlShouBanLi_en: "/api/weben/goods/list?class_type=Garage Kits",
  buyUrlIP_en: "/api/weben/goods/list?class_type=IP sprout",
  buyUrlKangYang_en: "/api/weben/goods/list?class_type=Health care products",
  buyUrlMeiYan_en: "/api/weben/goods/list?class_type=Beauty products",
  vrUrl_en: "/api/weben/vr/list?page=1",
  videoUrl_en: "/api/weben/video/list?page=1",
  serviceTraffic_en: "/api/weben/route/detail",
  serviceNotice_en: "/api/weben/notice/list",
  homeBannerUrl_en: "/api/weben/indexbanner/detail",

};
export default config;
