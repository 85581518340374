<template>
  <div>
    <div id="navApp">
      <!-- <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>-->
    </div>
    <router-view />
  </div>
</template>

<style>
#navApp {
  min-height: 100%;
}
</style>
