<template>
  <div class="homePageWrap">
    <m-layout-bar :bookhandle="bookhandle">
      <div class="homeWrap">
        <!-- -->
        <!--  -->
        <div class="bannerWrap">
          <!-- 轮播 {delay:4000}-->
          <swiper
            class="swiper-container"
            :navigation="{nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev'}"
            slidesPerView="1"
            :pagination="{ clickable: true }"
            :autoplay="false"
            :loop="false"
          >
            <swiper-slide class="swiper-slide" v-for="(item, index) in banners" :key="index">
              <img style="width:100%; height: 100%" :src="baseUrl + item" alt />
            </swiper-slide>
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
          </swiper>
          <!-- 轮播完毕 -->
          <!-- <img :src="require('../assets/image/home/banner.png')" alt /> -->
        </div>
        <!-- 铁定溜溜 -->
        <div class="tieDingLiuLiuWrap bigWrap">
          <div class="titleWrap">
            <span class="title">铁定溜溜</span>
            <img :src="require('../assets/image/home/tieDingLiuLiu_title.png')" alt />
          </div>
          <div class="contentWrap">
            <router-link tag="div" to="/summary" class="group shadowBox maskWrapD ifocus_up">
              <div class="imgWrap">
                <img :src="require('../assets/image/home/tieDingLiuLiu_1.png')" alt />
              </div>

              <div class="titleWrap">
                <span class="title textline1">乐园简介</span>
                <img :src="require('../assets/image/home/tieDingLiuLiu_gap.png')" alt />
              </div>
              <div
                class="detail textline3"
              >铁定溜溜位于温州市大荆镇，村域面积达2.13平方公里，是一个主要以铁皮石斛为核心，集合主题游乐、田园休闲、旅居度假、科普教育为一体的田园综合体项目。</div>
            </router-link>
            <router-link tag="div" to="/service/guide" class="group shadowBox maskWrapD ifocus_up">
              <div class="imgWrap">
                <img :src="require('../assets/image/home/tieDingLiuLiu_2.png')" alt />
              </div>
              <div class="titleWrap">
                <span class="title">导游导览</span>
                <img :src="require('../assets/image/home/tieDingLiuLiu_gap.png')" alt />
              </div>
              <div
                class="detail"
              >铁定溜溜位于温州市大荆镇，村域面积达2.13平方公里，是一个主要以铁皮石斛为核心，集合主题游乐、田园休闲、旅居度假、科普教育为一体的田园综合体项目。</div>
            </router-link>
            <router-link tag="div" to="/service/notice" class="group shadowBox maskWrapD ifocus_up">
              <div class="imgWrap">
                <img :src="require('../assets/image/home/tieDingLiuLiu_3.png')" alt />
              </div>
              <div class="titleWrap">
                <span class="title">游玩须知</span>
                <img :src="require('../assets/image/home/tieDingLiuLiu_gap.png')" alt />
              </div>
              <div
                class="detail"
              >铁定溜溜位于温州市大荆镇，村域面积达2.13平方公里，是一个主要以铁皮石斛为核心，集合主题游乐、田园休闲、旅居度假、科普教育为一体的田园综合体项目。</div>
            </router-link>
          </div>
        </div>
        <!-- 嗨转乐园 -->
        <div class="haiZhuanLeYuanWrap bigWrap bg">
          <!-- <div
          class="haiZhuanLeYuanWrap bigWrap bg"
          :style="{background:'url('+hiBgImgUrl+')',backgroundSize:'100% 100%'}"
          >-->
          <img class="bg" :src="require('../assets/image/home/hi_bg.png')" alt />
          <div class="titleWrap">
            <span class="title">嗨转乐园</span>
            <img :src="require('../assets/image/home/vr_title.png')" alt />
          </div>
          <div class="contentWrap">
            <div class="group ifocus_up">
              <img :src="require('../assets/image/home/hi_1.jpg')" alt />
              <router-link tag="div" to="/food/xiCan" class="btnWrap ifocus_shadow ifocus_up">
                <span class="title textline1">吃得溜溜</span>
                <span class="subTitle textline1">chi de liu liu</span>
              </router-link>
            </div>
            <div class="group sleeping ifocus_up">
              <img :src="require('../assets/image/home/hi_2.png')" alt />
              <router-link tag="div" to="/stay" class="btnWrap ifocus_shadow ifocus_up">
                <span class="title textline1">住得溜溜</span>
                <span class="subTitle textline1">zhu de liu liu</span>
              </router-link>
            </div>
            <div class="group playing ifocus_up">
              <img :src="require('../assets/image/home/hi_3.png')" alt />
              <router-link tag="div" to="/play" class="btnWrap ifocus_shadow ifocus_up">
                <span class="title textline1">玩得溜溜</span>
                <span class="subTitle textline1">wan de liu liu</span>
              </router-link>
            </div>
            <div class="group shopping ifocus_up">
              <img :src="require('../assets/image/home/hi_4.png')" alt />
              <router-link tag="div" to="/buy" class="btnWrap ifocus_shadow ifocus_up">
                <span class="title textline1">购得溜溜</span>
                <span class="subTitle textline1">gou de liu liu</span>
              </router-link>
            </div>
          </div>
        </div>
        <!-- vr/视频极致体验 -->
        <div class="vrWrap bigWrap">
          <div class="titleWrap">
            <span class="title textline1">VR/视频极致体验</span>
            <img :src="require('../assets/image/home/vr_title.png')" alt />
          </div>
          <div class="contentWrap">
            <div
              class="group vr shadowBox maskWrap size toShow ifocus_up"
              @click="watchVR(vrInfo.link,$event)"
            >
              <img :src="baseUrl + vrInfo.cover" alt />
              <div class="titleWrap">
                <span class="title textline1">{{vrInfo.title}}</span>
                <span class="subTitle textline1">{{vrInfo.info}}</span>
              </div>
              <router-link class="otherWrap textline1" data-flag="vr" to="/panorama">
                <!-- <div class="otherWrap"> -->
                <img data-flag="vr" :src="require('../assets/image/home/vr_icon_2.png')" alt />
                查看更多VR视频
                <!-- </div> -->
              </router-link>
              <div class="decorateWrap">
                <div>
                  <img :src="require('../assets/image/home/vr_icon_2.png')" alt />
                  VR体验
                </div>
              </div>
            </div>

            <div
              class="group video shadowBox maskWrap size toShow ifocus_up"
              @click="watchVideo(videoInfo.link,$event)"
            >
              <img :src="baseUrl + videoInfo.cover" alt @click="watchVideo(videoInfo.link)" />
              <div class="titleWrap">
                <span class="title textline1">{{videoInfo.title}}</span>
                <span class="subTitle textline1">{{videoInfo.info}}</span>
              </div>
              <router-link class="otherWrap textline1" data-flag="video" to="/video">
                <!-- <div class="otherWrap"> -->
                <img data-flag="video" :src="require('../assets/image/home/vr_icon_3.png')" alt />
                查看更多视频
                <!-- </div> -->
              </router-link>
              <div class="decorateWrap textline1">
                <div>
                  <img :src="require('../assets/image/home/vr_icon_3.png')" alt />
                  精彩视频
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 推荐路线 -->
        <div class="tuiJianLuXianWrap bigWrap bg">
          <img class="bg" :src="require('../assets/image/home/tui_bg.png')" alt />
          <div class="titleWrap">
            <span class="title">推荐路线</span>
            <img :src="require('../assets/image/home/tui_title.png')" alt />
          </div>
          <div class="contentWrap">
            <router-link to="/ticketRoute/route?active=1" class="group luXian qinZi maskWrapD">
              <img :src="require('../assets/image/home/tui_2.png')" alt />
              <div class="titleWrap textline1">
                <span class="title">亲子生活</span>
                <span class="subTitle">PARENT - CHILD LOHAS</span>
              </div>
            </router-link>
            <router-link to="/ticketRoute/route?active=1" class="group luXian tianYuan maskWrapD">
              <img :src="require('../assets/image/home/tui_3.png')" alt />
              <div class="titleWrap textline1">
                <span class="title">田园休闲</span>
                <span class="subTitle">RURAL LEISURE</span>
              </div>
            </router-link>
            <router-link to="/ticketRoute/route?active=1" class="group luXian kangYang maskWrapD">
              <img :src="require('../assets/image/home/tui_2.png')" alt />
              <div class="titleWrap textline1">
                <span class="title">康养中心</span>
                <span class="subTitle">PARENT - CHILD LOHAS</span>
              </div>
            </router-link>
          </div>
        </div>

        <!-- 活动资讯 -->
        <div class="huoDongZiXunWrap bigWrap bg">
          <div class="titleWrap">
            <span class="title textline1">活动资讯</span>
            <img :src="require('../assets/image/home/huodong_title.png')" alt />
          </div>
          <div class="contentWrap">
            <div
              class="group ifocus_up ifocus_shadow"
              v-for="(item,index) in activeInfo"
              :key="index"
            >
              <img :src="baseUrl + item.main_image_id" alt />
              <div class="titleWrap">
                <span class="title textline2">{{item.title}}</span>
                <span class="subTitle time textline1">{{item.create_time}}</span>
              </div>
              <div class="detail textline3">{{item.subtitle}}</div>
              <div class="decorateWrap">
                <router-link to="/eventInfo/announcement">
                  <img class="more" :src="require('../assets/image/home/huodong_more.png')" alt />
                </router-link>
              </div>
            </div>
            <!-- <div class="group ifocus_up ifocus_shadow">
              <img :src="require('../assets/image/home/huodong_2.png')" alt />
              <div class="titleWrap">
                <span class="title textline2">一起趣“鬼混”！儿童免费入场，更有100个入园名额送送送！</span>
                <span class="subTitle time textline1">2020-10-10</span>
              </div>
              <div
                class="detail textline3"
              >铁定溜溜位于温州市大荆镇，村域面积达2.13平方公里，是一个主要以铁皮石斛为核心，集合主题游乐、田园休闲、旅居度假、科普教育为一体的田园综合体项目。</div>
              <div class="decorateWrap">
                <router-link to="/eventInfo/announcement">
                  <img class="more" :src="require('../assets/image/home/huodong_more.png')" alt />
                </router-link>
              </div>
            </div>
            <div class="group ifocus_up ifocus_shadow">
              <img :src="require('../assets/image/home/huodong_3.png')" alt />
              <div class="titleWrap">
                <span class="title textline2">一起趣“鬼混”！儿童免费入场，更有100个入园名额送送送！</span>
                <span class="subTitle time textline1">2020-10-10</span>
              </div>
              <div
                class="detail textline3"
              >铁定溜溜位于温州市大荆镇，村域面积达2.13平方公里，是一个主要以铁皮石斛为核心，集合主题游乐、田园休闲、旅居度假、科普教育为一体的田园综合体项目。</div>
              <div class="decorateWrap">
                <router-link to="/eventInfo/announcement">
                  <img class="more" :src="require('../assets/image/home/huodong_more.png')" alt />
                </router-link>
              </div>
            </div>-->
          </div>
          <router-link class="btn trans" to="/eventInfo/travel">更多活动</router-link>
        </div>
        <!-- 遮罩 -->
        <div class="iMask" v-show="showFlag">
          <div class="maskWrap">
            <a href class="btn vBtn" @click="goLocation">
              <!-- <img :src="require('../assets/image/home/alert_btn.png')" alt /> -->
            </a>
            <!-- <div class="code2">
              <img class="code" :src="require('../assets/image/head/code2.png')" alt />
            </div>-->
            <img class="code" :src="require('../assets/image/home/alert.png')" alt />
            <img
              class="close"
              @click="closeMask"
              :src="require('../assets/image/stay/close.png')"
              alt
            />
          </div>
        </div>
      </div>
    </m-layout-bar>
  </div>
</template>

<script>
// @ is an alias to /src
import Layout from "@/components/LayoutBar";

import SwiperCore, { Navigation, Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
// import style (>= Swiper 6.x)
import "swiper/swiper-bundle.css";
SwiperCore.use([Navigation, Pagination, Autoplay]);

import axios from "axios";
import config from "@/utils/config";

import mx_utils from "@/mixins/mx_utils";

export default {
  components: {
    [Layout.name]: Layout,
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      // "banner.png",
      //   "banner.png",
      //   "banner.png",
      //   "banner.png",
      //   "banner.png"
      showFlag: true,
      hiBgImgUrl: require("../assets/image/home/hi_bg.png"),
      banners: [],
      dataInfo: new Array(),
      vrInfo: {},
      videoInfo: {},
      activeInfo: new Array()
    };
  },
  mixins: [mx_utils],
  created() {
    let sMaskBeUsed = sessionStorage.getItem("maskBeUsed");
    if (sMaskBeUsed == null || !JSON.parse(sMaskBeUsed)) {
      this.showFlag = true;
      // console.log(
      //   "in created 1 !!!",
      //   sMaskBeUsed,
      //   typeof sMaskBeUsed,
      //   JSON.parse(sMaskBeUsed)
      // );
    } else if (JSON.parse(sMaskBeUsed)) {
      // console.log("in created 3 !!!", JSON.parse(sMaskBeUsed));
      this.showFlag = false;
    }
  },
  computed: {
    baseUrl() {
      return config.webBaseUrl;
    }
  },
  mounted() {
    // homeBannerUrl
    this.getData(config.homeBannerUrl).then(res => {
      let obj = {};
      if (res.length > 0) {
        let obj = res[0];
        if (Object.keys(obj).length != 0) {
          let list = obj.photos.split(",");
          if (list.length > 0) {
            this.banners = list;
          }
          // console.log("banner 返回：", res, list);
        }
      }
    });
    this.getData(config.vrUrl).then(res => {
      if (res.length > 0) {
        this.vrInfo = res[0];
      }
      // console.log("vr 返回：", res);
    });
    this.getData(config.videoUrl).then(res => {
      if (res.length > 0) {
        this.videoInfo = res[0];
      }
      // console.log("videoInfo 返回：", res);
    });
    this.getData(config.eventTravelUrl).then(res => {
      if (res.length > 0) {
        this.activeInfo = res.slice(0, 3);
        // console.log("旅游信息 返回：", res, this.activeInfo);
      }
    });
  },
  methods: {
    watchVR(link, e) {
      // console.log("e.target:", e.target, e.currentTarget, "vrlink:", link);
      if (
        e.target.getAttributeNode("data-flag") != null &&
        e.target.getAttributeNode("data-flag").value == "vr"
      )
        return;
      if (link == "") return;
      let openWin = window.open(link);
      openWin.opener = null;
    },
    watchVideo(link, e) {
      // console.log("videolink:", link);
      // console.log("e.target:", e.target, e.currentTarget, "vrlink:", link);
      if (
        e.target.getAttributeNode("data-flag") != null &&
        e.target.getAttributeNode("data-flag").value == "video"
      )
        return;
      if (link == "") return;
      let openWin = window.open(this.baseUrl + link);
      openWin.opener = null;
    },
    async getData(url) {
      try {
        let res = await axios.get(url);
        if (res.data.code == "0" && res.data.data instanceof Array) {
          if (res.data.data.length > 0) {
            let tempData = JSON.parse(JSON.stringify(res.data.data));
            this.dataInfo = tempData;
            // console.log("res.data.data:", this.dataInfo);
            return tempData;
          }
        }
        return null;
      } catch (e) {
        console.log("res err:", e);
      }
    },
    closeMask() {
      this.showFlag = false;
      // this.$store.state.maskBeUsed = true;
      sessionStorage.setItem("maskBeUsed", true);
    },
    bookhandle() {
      this.showFlag = true;
      // console.log('我是home')
    }
  }
};
</script>
<style lang="scss" rel="stylesheet/scss">
@import "src/assets/styles/variables.scss";
.swiper-pagination-bullet {
  width: 17px;
  height: 17px;
  border: 2px solid #90c320;
  background: #fff;
}
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 23px;
  left: 0px;
}
.swiper-pagination-bullet-active {
  background-color: $main-white;
  width: 17px;
  border-radius: 17px;
}
.swiper-container-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 0 17px;
}
</style>

<style scoped lang="scss" rel="stylesheet/scss">
@import "src/assets/styles/variables.scss";

.iMask {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  background: rgba(0, 0, 0, 0.7);
  text-align: center;
  z-index: 99999;
  .maskWrap {
    position: absolute;
    // width:299px;
    // height:345px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .vBtn {
      width: 205px;
      height: 48px;
      position: absolute;
      top: 18px;
      right: 23px;
      border-radius: 30px;
      // width: 140px;
      // height: 35px;
      // position: absolute;
      // top: 23px;
      // right: 48px;
      // border-radius: 30px;
      // background: red;
      // background-image: url(~@/assets/image/home/alert_btn.png);
      // background-size: 100% 100%;
      // &:hover {
      //   background-image: url(~@/assets/image/home/alert_btn_h.png);
      //   background-size: 100% 100%;
      // }
    }
    > .code2 {
      position: absolute;
      width: 44px;
      height: 43px;
      bottom: 182px;
      right: 453px;
      > img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .code {
    margin-bottom: 10px;
    width: 1000px;
    cursor: default;
  }
  .close {
    cursor: pointer;
  }
}

.swiper-container {
  --swiper-theme-color: #ff6600;
  --swiper-navigation-color: #fff; /* 单独设置按钮颜色 */
  --swiper-navigation-size: 60px; /* 设置按钮大小 */
}

.swiper-pagination-bullet {
  width: 21px;
  height: 21px;
  border: 2px solid #90c320;
}

.swiper-slide {
  height: 793px !important;
}
// div.swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets {
//   bottom: 28px !important;
//   left: 0 !important;
//   width: 100% !important;
// }

.homeWrap {
  // padding-bottom:30px;
  .bannerWrap {
    width: 100%;
    // height: 800px;
    > img {
      width: 100%;
      height: 100%;
    }
  }

  // 公用--------------------------------
  img {
    cursor: pointer;
  }
  img.bg {
    width: 1920px; //100%;
  }

  .bg {
    position: relative;
    > .bg {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }
  }

  .bigWrap {
    > .titleWrap,
    > .contentWrap {
      width: 1400px;
      margin: 0 auto;
      .group {
        > .titleWrap {
          > .title,
          > .subTitle {
            display: block;
          }
          > .title {
            font-weight: bold;
            margin-bottom: 11px;
          }
        }
        > img {
          display: block;
        }
      }
    }
    > .titleWrap {
      position: relative;
      height: 210px;
      line-height: 4;
      text-align: center;
      font-size: 37px;
      font-weight: bold;
      > img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        z-index: -1;
      }
    }
  }
  // 公用 end--------------------------------

  // 铁定溜溜--------------------
  .tieDingLiuLiuWrap {
    > .titleWrap,
    > .contentWrap {
      width: 1400px;
      margin: 0 auto;
    }
    > .titleWrap {
      position: relative;
      height: 210px;
      line-height: 4;
      text-align: center;
      font-size: 37px;
      font-weight: bold;
      color: $font-red;
      > img {
        position: absolute;
        width: 328px;
        height: 72px;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        z-index: -1;
      }
    }
    .contentWrap {
      display: flex;
      justify-content: space-between;
      > .group {
        position: relative;
        flex: none;
        width: 32%;
        // height: 680px;
        box-sizing: border-box;
        // margin-right: 22px;
        cursor: pointer;

        border-radius: 5px;
        > .imgWrap {
          height: 447px;
          // height:66.2%;
          > img {
            width: 100%;
            height: 100%;
          }
        }
        .titleWrap,
        .detail {
          padding-left: 19px;
          padding-right: 15px;
        }
        .titleWrap {
          padding-top: 25px;
          .title {
            display: block;
            font-size: 36px;
            color: $font-grayDarkD;
            > img {
              position: absolute;
              left: 0px;
              bottom: 0px;
            }
          }
        }
        .detail {
          // padding: 34px 19px;

          font-size: 16px;
          color: $font-grayDarkD;
          line-height: 1.7;
          padding-top: 23px;
          padding-bottom: 29px;
        }
      }
    }
  }
  // 铁定溜溜-end-------------------

  // ------嗨转乐园---------------------------
  .haiZhuanLeYuanWrap {
    position: relative;
    margin-top: 125px;
    height: 899px;
    > .bg {
      width: 100%;
      height: 100% !important;
    }
    // > .bg {
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   z-index: -1;
    // }
    > .titleWrap {
      color: $main-white;
      margin-bottom: 24px;
    }
    > .contentWrap {
      display: flex;
      justify-content: space-between;
      height: 681px;
      > .group {
        position: relative;
        border-radius: 10px;
        flex: none;
        width: 21%;
        // width: 296px;
        height: 378px;
        // margin-right: 80px;

        > img {
          width: 100%;
          height: 378px;
          border-radius: 10px;
        }
        > .btnWrap {
          position: absolute;
          border: 2px solid $main-white;
          color: $main-white;
          width: 214px;
          left: 0;
          right: 0;
          margin: 0 auto;
          bottom: 23px;
          box-sizing: border-box;
          border-radius: 10px;
          text-align: center;
          padding: 10px 0px 18px 0px;
          background: rgba(251, 185, 42, 0.7);
          cursor: pointer;

          > span {
            display: block;
          }
          > .title {
            font-size: 19px;
          }
          > .subTitle {
            font-size: 12px;
          }
        }
      }

      > .group.sleeping {
        margin-top: 108px;
        .btnWrap {
          background: rgba(144, 195, 32, 0.7);
        }
      }
      > .group.playing {
        margin-top: 108px;

        .btnWrap {
          background: rgba(169, 111, 255, 0.7);
        }
      }
      > .group.shopping {
        .btnWrap {
          background: rgba(16, 136, 221, 0.7);
        }
      }
    }
  }
  // ------嗨转乐园-end--------------------------

  // VR体验----------------------------------------
  .vrWrap {
    > .titleWrap {
      color: $font-red;
    }
    .contentWrap {
      display: flex;
      justify-content: space-between;
      .group {
        position: relative;
        flex: none;
        width: 48%;
        overflow: hidden;
        // width: 640px;
        border-radius: 10px;
        > img {
          width: 100%;
          height: 378px;
        }
        > .titleWrap {
          color: $font-grayDarkD;
          text-align: center;
          padding-top: 15px;
          padding-bottom: 19px;
          height: 70px;
          box-sizing: border-box;

          > .title {
            font-size: 19px;
          }
          > .subTitle {
            font-size: 13px;
            padding-left: 20px;
            padding-right: 20px;
          }
        }
        > .otherWrap {
          display: block;
          height: 71px;
          color: $main-white;
          font-size: 19px;
          background: $main-greenGrass;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
          padding: 25px 0px;
          text-align: center;
          box-sizing: border-box;
          > img {
            margin-right: 16px;
            vertical-align: top;
          }
        }
        &.vr {
          > .otherWrap {
            &:hover {
              background: $main-green;
            }
          }
        }
        &.video {
          > .otherWrap {
            &:hover {
              background: $main-blueDark;
            }
          }
        }
        > .decorateWrap {
          position: absolute;
          width: 147px;
          height: 50px;
          line-height: 50px;
          color: $main-white;
          font-size: 12px;
          background: $main-greenGrass;
          border-bottom-right-radius: 50px;
          border-top-left-radius: 8px;
          top: 0px;
          left: 0px;
          img {
            margin-left: 23px;
            margin-right: 4px;
            vertical-align: middle;
          }
        }
      }

      .group.video {
        > .otherWrap,
        > .decorateWrap {
          background: $main-blue;
        }
      }
    }
  }
  // VR体验-end---------------------------------------

  // 推荐路线----------------------------------
  .tuiJianLuXianWrap {
    display: block;
    margin-top: 125px;
    border: 1px solid transparent;
    > .bg {
      width: 100%;
      height: 100% !important;
    }
    > .titleWrap {
      height: 154px;

      > .title {
        color: $main-red;
      }
      > img {
        top: 33px;
      }
    }
    > .contentWrap {
      display: flex;
      justify-content: space-between;
      margin-bottom: 125px;
      > .group {
        flex: none;
        width: 32%;
        // width: 450px;
        height: 404px;
        > img {
          width: 100%;
          height: 312px;
        }
        > .titleWrap {
          color: $main-white;
          padding: 29px 0;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
          > .title {
            display: inline;
            font-size: 26px;
            margin-left: 23px;
            margin-right: 18px;
          }
          > .subTitle {
            display: inline;
            font-size: 15px;
          }
        }
      }
      > .group.qinZi {
        > .titleWrap {
          background: $main-blue;
        }
      }
      > .group.tianYuan {
        > .titleWrap {
          background: $main-greenGrass;
        }
      }
      > .group.kangYang {
        > .titleWrap {
          background: $main-redQuestion;
        }
      }
    }
  }
  // 推荐路线-end---------------------------------

  // 活动资讯
  .huoDongZiXunWrap {
    background: $main-gray;
    // margin-top: 125px;
    padding-bottom: 30px;
    > .titleWrap {
      height: 154px;
      > .title {
        color: $main-red;
      }
      > img {
        z-index: 0 !important;
        top: 31px;
      }
    }
    > .contentWrap {
      display: flex;
      justify-content: space-between;
      .group {
        flex: none;
        width: 26%;
        // flex: 0;
        // width: 360px;
        background: $main-white;
        border-radius: 10px;
        overflow: hidden;
        > img {
          width: 100%;
          height: 214px;
        }
        > .titleWrap {
          padding-left: 16px;
          padding-right: 16px;
          .title {
            margin-top: 17px;
            font-size: 17px;
          }

          .subTitle.time {
            margin-top: 8px;
            font-size: 10px;
            color: $font-grayDark;
          }
        }
        > .detail {
          padding-left: 16px;
          padding-right: 16px;
          height: 66px;
          margin-top: 12px;
          font-size: 13px;
          color: $font-grayDarkD;
          line-height: 1.7;
        }
        > .decorateWrap {
          padding: 12px 16px;
          text-align: right;
          > .more {
            border: 3px solid transparent;
          }
        }
      }
    }
    > .btn {
      border: 1px solid $main-black;
      display: block;
      width: 189px;
      height: 55px;
      line-height: 55px;
      text-align: center;
      margin: 30px auto 0 auto;
      cursor: pointer;
      color: $font-grayDarkD;

      &:hover {
        // border-radius: 20px;
        background: $font-grayDark;
        color: $main-white;
      }
    }
  }
}

@media (min-width: $screen-lg-min) and (max-width: $screen-sp-min) {
  .homeWrap {
    .bigWrap {
      > .titleWrap,
      > .contentWrap {
        width: 1100px;
      }
    }
    .haiZhuanLeYuanWrap {
      > .contentWrap {
        > .group {
          width: 24%;
        }
      }
    }
  }

  // .homeWrap {
  //   .tieDingLiuLiuWrap {
  //      > .contentWrap{
  //        width:1000px;
  //        >.group{
  //          >.imgWrap{
  //            >img{
  //              width:100%;
  //              height:100%;
  //            }
  //          }
  //        }
  //      }
  //   }
  // }
}
</style>

