<template>
  <div class="headWrap">
    <div class="gap"></div>
    <div class="content">
      <div class="topWrap">
        <div class="dateWrap">
          <span>{{weatherData.date}}&nbsp;&nbsp;&nbsp;{{weatherTxt}}</span>
          <!-- <span>2021年9月14日 星期二 温州 阴 10℃~14℃</span> -->
          <m-language-bar></m-language-bar>
        </div>
      </div>

      <router-link class="logoWrap" to="/">
        <img class="logo ifocus_up" :src="require('../assets/image/head/logo_h.png')" alt />
      </router-link>
      <div class="menuWrap">
        <ul class="menuItemWrap" data-index="5">
          <li
            class="item home trans"
            :class="{'active-home':activeName==='home'}"
            data-index="0"
            data-name="home"
            @mouseenter="enter"
            @mouseleave="leave"
          >
            <router-link to="/">
              <img
                v-if="activeName==='home'"
                :src="require('../assets/image/head/index_h.png')"
                alt
              />
              <img v-else :src="require('../assets/image/head/index.png')" alt />
              <span>首页</span>
            </router-link>
            <ul class="subItemWrap touMing" v-show="showFlag">
              <li class="subItem">
                <router-link to></router-link>
              </li>
              <li class="subItem">
                <router-link to></router-link>
              </li>
              <li class="subItem">
                <router-link to></router-link>
              </li>
              <li class="subItem">
                <router-link to></router-link>
              </li>
              <li class="subItem">
                <router-link to></router-link>
              </li>
              <li class="subItem">
                <router-link to></router-link>
              </li>
              <li class="subItem">
                <router-link to></router-link>
              </li>
            </ul>
          </li>
          <li
            class="item play trans"
            :class="{'active-play':activeName==='play'}"
            data-index="1"
            data-name="play"
            @mouseenter="enter"
            @mouseleave="leave"
          >
            <router-link to>
              <img
                v-if="activeName==='play'"
                :src="require('../assets/image/head/ticket_h.png')"
                alt
              />
              <img v-else :src="require('../assets/image/head/ticket.png')" alt />
              <span>游玩攻略</span>
            </router-link>
            <ul class="subItemWrap" :class="{'active-play':activeName==='play'}" v-show="showFlag">
              <li class="subItem">
                <router-link to="/summary">园区简介</router-link>
              </li>
              <li class="subItem">
                <router-link to="/play">玩得溜溜</router-link>
              </li>
              <li class="subItem">
                <router-link to="/food/xiCan">吃得溜溜</router-link>
              </li>
              <li class="subItem">
                <router-link to="/stay">住得溜溜</router-link>
              </li>
              <li class="subItem">
                <router-link to="/buy">购得溜溜</router-link>
              </li>
              <li class="subItem">
                <router-link to="/ticketRoute/ticket?active=0">票价信息</router-link>
              </li>
              <li class="subItem">
                <router-link to="/ticketRoute/route?active=1">推荐路线</router-link>
              </li>
            </ul>
          </li>
          <li
            class="item vr trans"
            :class="{'active-vr':activeName==='vr'}"
            data-index="2"
            data-name="vr"
            @mouseenter="enter"
            @mouseleave="leave"
          >
            <router-link to>
              <img v-if="activeName==='vr'" :src="require('../assets/image/head/vr_h.png')" alt />
              <img v-else :src="require('../assets/image/head/vr.png')" alt />

              <span>VR/视频</span>
            </router-link>
            <ul class="subItemWrap" :class="{'active-vr':activeName==='vr'}" v-show="showFlag">
              <li class="subItem">
                <router-link to="/panorama">720°全景</router-link>
              </li>
              <li class="subItem">
                <router-link to="/video">视频</router-link>
              </li>
              <li class="subItem touMing">
                <router-link to></router-link>
              </li>
              <li class="subItem touMing">
                <router-link to></router-link>
              </li>
              <li class="subItem touMing">
                <router-link to></router-link>
              </li>
              <li class="subItem touMing">
                <router-link to></router-link>
              </li>
              <li class="subItem touMing">
                <router-link to></router-link>
              </li>
            </ul>
          </li>
          <li
            class="item info trans"
            :class="{'active-info':activeName==='info'}"
            data-index="3"
            data-name="info"
            @mouseenter="enter"
            @mouseleave="leave"
          >
            <router-link to="/eventInfo">
              <img v-if="activeName==='info'" :src="require('../assets/image/head/info_h.png')" alt />
              <img v-else :src="require('../assets/image/head/info.png')" alt />

              <span>活动资讯</span>
            </router-link>
            <ul class="subItemWrap touMing" v-show="showFlag">
              <li class="subItem">
                <router-link to></router-link>
              </li>
              <li class="subItem">
                <router-link to></router-link>
              </li>
              <li class="subItem">
                <router-link to></router-link>
              </li>
              <li class="subItem">
                <router-link to></router-link>
              </li>
              <li class="subItem">
                <router-link to></router-link>
              </li>
              <li class="subItem">
                <router-link to></router-link>
              </li>
              <li class="subItem">
                <router-link to></router-link>
              </li>
            </ul>
          </li>
          <li
            class="item service trans"
            :class="{'active-service':activeName==='service'}"
            data-index="4"
            data-name="service"
            @mouseenter="enter"
            @mouseleave="leave"
          >
            <router-link to>
              <img
                v-if="activeName==='service'"
                :src="require('../assets/image/head/service_h.png')"
                alt
              />
              <img v-else :src="require('../assets/image/head/service.png')" alt />

              <span>游客服务</span>
            </router-link>
            <ul
              class="subItemWrap"
              :class="{'active-service':activeName==='service'}"
              v-show="showFlag"
            >
              <li class="subItem">
                <router-link to="/service/traffic">交通信息</router-link>
              </li>
              <li class="subItem">
                <router-link to="/service/guide">导游导览</router-link>
              </li>
              <li class="subItem">
                <router-link to="/service/feedback">意见反馈</router-link>
              </li>
              <li class="subItem">
                <router-link to="/service/notice">游玩须知</router-link>
              </li>
              <li class="subItem">
                <router-link to="/service/question">常见问题</router-link>
              </li>
              <li class="subItem touMing">
                <router-link to></router-link>
              </li>
              <li class="subItem touMing">
                <router-link to></router-link>
              </li>
            </ul>
          </li>
          <li
            class="item about trans"
            :class="{'active-about':activeName==='about'}"
            data-index="5"
            data-name="about"
            @mouseenter="enter"
            @mouseleave="leave"
          >
            <router-link to>
              <img
                v-if="activeName==='about'"
                :src="require('../assets/image/head/about_h.png')"
                alt
              />
              <img v-else :src="require('../assets/image/head/about.png')" alt />
              <span>关于我们</span>
            </router-link>
            <ul
              class="subItemWrap"
              :class="{'active-about':activeName==='about'}"
              v-show="showFlag"
            >
              <li class="subItem">
                <router-link to="/company">公司简介</router-link>
              </li>
              <li class="subItem">
                <router-link to="/contactUs">联系我们</router-link>
              </li>
              <li class="subItem touMing">
                <router-link to></router-link>
              </li>
              <li class="subItem touMing">
                <router-link to></router-link>
              </li>
              <li class="subItem touMing">
                <router-link to></router-link>
              </li>
              <li class="subItem touMing">
                <router-link to></router-link>
              </li>
              <li class="subItem touMing">
                <router-link to></router-link>
              </li>
            </ul>
          </li>
        </ul>
        <div class="menu" :class="menu_color" v-show="showFlag"></div>
      </div>
      <div class="btn trans" @click="book">在线预订</div>
    </div>
    <!-- 遮罩 -->
    <div class="iMask" v-show="showMaskFlag">
      <div class="maskWrap">
        <img class="code" :src="require('../assets/image/home/alert.png')" alt />
        <img class="close" @click="closeMask" :src="require('../assets/image/stay/close.png')" alt />
      </div>
    </div>
  </div>
</template>
<script type="text/ecmascript-6">
import axios from "axios";
import config from "@/utils/config";
import Language from "@/components/Language";

export default {
  name: "m-head-bar",
  components: {
    [Language.name]: Language
  },
  data() {
    return {
      showFlag: false,
      showMaskFlag: false,
      activeName: "",
      weatherData: {},
      weatherTxt: ""
    };
  },
  mounted() {
    this.getWeatherData(config.weatherUrl);
    
  },
  methods: {
    
    async getWeatherData(url) {
      try {
        let res = await axios.get(url);

        if (res.status == 200 && res.data.message == "查询成功") {
          let resInfo = res.data;
          let dateInfo = resInfo.date.substring(0, 10);
          let add = resInfo.weatherJson.name;
          let tianQi = resInfo.weatherJson.seven_tianqi[0].tianqi;
          let wenDu = resInfo.weatherJson.seven_tianqi[0].wendu;

          let tempDate = new Date(dateInfo);
          let year = tempDate.getFullYear();
          let month = tempDate.getMonth() + 1;
          let date = tempDate.getDate();
          let day = "星期" + "日一二三四五六".charAt(tempDate.getDay());

          this.weatherData = {
            date: year + "年" + month + "月" + date + "日",
            day: day,
            address: add,
            tianQi: tianQi,
            wenDu: wenDu
          };
          this.weatherTxt =
            this.weatherData.day +
            " " +
            this.weatherData.address +
            " " +
            this.weatherData.tianQi +
            " " +
            this.weatherData.wenDu;
          // console.log("weath res:", res, "天气：", this.weatherData);
        }
      } catch (e) {
        console.log("res err:", e);
      }
    },
    closeMask() {
      this.showMaskFlag = false;
    },
    enter(event) {
      this.showFlag = true;
      this.activeName = event.currentTarget.getAttributeNode("data-name").value;
      if (!this.activeName) {
        this.activeName = "";
      }
    },
    leave(event) {
      this.showFlag = false;
      this.activeName = "";
    },
    book(e) {
      this.showMaskFlag = true;

      this.$emit("bookhandle");
    }
  },
  computed: {
    menu_color() {
      return "active-" + this.activeName;
    }
  }
};
</script>

<style scoped lang="scss" rel="stylesheet/scss">
@import "src/assets/styles/variables.scss";
// 遮罩
.iMask {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  background: rgba(0, 0, 0, 0.7);
  text-align: center;
  z-index: 99999;
  .maskWrap {
    position: absolute;
    // width:299px;
    // height:345px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .code {
    margin-bottom: 10px;
    width: 1000px;
    cursor: default;
  }
  .close {
    cursor: pointer;
  }
}

.touMing {
  opacity: 0;
}
.headWrap {
  // position: fixed;
  position: relative;
  // width: 1920px;
  width: 100%;

  .gap {
    width: 100%;
    height: 120px;
    // background: red;
  }
  .content {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99999;
  }

  .logo {
    position: absolute;
    top: 50px;
    left: 13%;
    width: 140px;
    height: 50px;
    cursor: pointer;
  }
  .topWrap {
    height: 33px;
    background: $main-gray;
    .dateWrap {
      position: relative;
      width: 1400px;
      height: 33px;
      line-height: 33px;
      background: $main-gray;
      color: $font-gray;
      font-size: 14px;
      margin: 0 auto;
      // padding-left: 261px;
      // .phone {
      //   position: absolute;
      //   top: 0;
      //   right: 50px;
      //   z-index: 1;
      //   background: #fff;
      //   border-radius: 4px;
      //   // border: 1px solid $main-gray;
      //   > .txt {
      //     width: 177px;
      //     height: 33px;
      //     background: $main-gray;
      //     padding-left: 8px;
      //     padding-right: 8px;
      //     color: $main-black;
      //     font-size: 18px;
      //     line-height: 33px;
      //     > .dIcon {
      //       display: inline-block;
      //       width: 18px;
      //       height: 100%;
      //       vertical-align: middle;
      //       margin-right: 8px;
      //       line-height: 2;
      //     }
      //     > .drpArrow {
      //       display: inline-block;
      //       width: 18px;
      //       height: 100%;
      //       vertical-align: middle;
      //       margin-left: 13px;
      //     }
      //   }
      //   > .drpWrap {
      //     position: absolute;
      //     border: 1px solid $main-gray;
      //     top: 43px;
      //     border-radius: 8px;
      //     > li {
      //       // width: 177px;
      //       // height: 40px;
      //       color: $main-black;
      //       background: $main-white;
      //       font-size: 18px;

      //       // border: 1px solid red;
      //       &:nth-of-type(1) {
      //         // padding-top: 4px;
      //         border-top-left-radius: 8px;
      //         border-top-right-radius: 8px;
      //       }
      //       &:nth-of-type(2) {
      //         // padding-top: 4px;
      //         border-bottom-left-radius: 8px;
      //         border-bottom-right-radius: 8px;
      //       }
      //       .linkWrap {
      //         display: flex;
      //         justify-content: space-between;
      //         align-items: center;
      //         width: 167px;
      //         height: 45px;
      //         line-height: 45px;
      //         padding-left: 15px;
      //         padding-right: 7px;

      //         &:hover {
      //           background: rgba(251, 185, 42, 0.2);
      //         }
      //         .flag {
      //           width: 27px;
      //           height: 22px;
      //           vertical-align: middle;
      //           margin-right: 9px;
      //         }
      //         .txtWrap {
      //           display: flex;
      //           justify-content: space-between;
      //           width: calc(100% - 37px);
      //         }
      //       }
      //       .linkWrap.a1 {
      //         // padding-top: 4px;
      //         border-top-left-radius: 8px;
      //         border-top-right-radius: 8px;
      //       }
      //       .linkWrap.a2 {
      //         // padding-top: 4px;
      //         border-bottom-left-radius: 8px;
      //         border-bottom-right-radius: 8px;
      //       }
      //       a.active,
      //       a:focus,
      //       a:active,
      //       a,
      //       a:hover {
      //         color: $main-black;
      //       }

      //       // > span {
      //       //   display: inline-block;
      //       // }
      //     }
      //     &:before {
      //       content: "";
      //       position: absolute;
      //       background-image: url(~@/assets/image/head/dialog.png);
      //       background-repeat: no-repeat;
      //       top: -7px;
      //       left: 30px;
      //       width: 16px;
      //       height: 10px;
      //     }
      //   }
      // }
    }
  }

  .menuWrap {
    text-align: center;
    // border: 1px solid transparent;
    background: $main-white;
  }
  .menuItemWrap {
    position: relative;
    display: inline;
    font-size: 17px;
    .item {
      position: relative;
      display: inline-block;
      width: 134px;
      height: 76px;
      padding-top: 7px;
      border-left: 1px solid $main-gray;
      border-right: 1px solid $main-gray;
      border-bottom: 1px solid $main-gray;
      font-size: 15px;
      font-weight: bold;

      a {
        display: block;
        height: 100%;
        img {
          max-height: 34px;
          margin-bottom: 8px;
        }
        span {
          display: block;
          margin-bottom: 6px;
        }
      }
      .subItemWrap {
        margin-top: 4px;
        // padding-top: 2px;
        position: absolute;
        width: 134px;
        display: block;
        z-index: 100;
        padding-bottom: 5px;
        .subItem {
          position: relative;
          display: block;
          height: 47px;
          line-height: 50px;
          > a {
            display: block;
            padding-top: 10px;
            height: 38px;
            text-align: center;
            // color:$main-white;
            color: $main-white;
            // background:yellow;
          }
        }
        .subItem:before {
          content: "";
          position: absolute;
          width: 7px;
          height: 10px;
          background-image: url(~@/assets/image/head/arrow.png);
          left: 13px;
          top: 31px;
          display: none;
        }
        .subItem:after {
          content: "";
          position: absolute;
          width: 64px;
          height: 2px;
          /* bottom: 0; */
          background: $main-white;
          display: block;
          margin: 0 auto;
          left: 0;
          right: 0;
          display: none;
        }
        .subItem:hover:after,
        .subItem:hover:before {
          display: block;
        }
      }
    }

    .home {
      border-bottom: 4px solid $main-blue;
      a:-webkit-any-link {
        color: $main-blue;
      }
      .subItem:after,
      .subItem:hover:after {
        display: none !important;
      }
      a,
      a:focus,
      a:hover,
      a.active {
        color: $main-blue;
      }
    }

    .play {
      border-bottom: 4px solid $main-red;
      a:-webkit-any-link {
        color: $main-red;
      }
      a,
      a:focus,
      a:hover,
      a.active {
        color: $main-red;
      }
    }

    .vr {
      color: $main-yellow;
      border-bottom: 4px solid $main-yellow;
      a:-webkit-any-link {
        color: $main-yellow;
      }
      a,
      a:focus,
      a:hover,
      a.active {
        color: $main-yellow;
      }
    }

    .info {
      color: $main-green;
      border-bottom: 4px solid $main-green;
      a:-webkit-any-link {
        color: $main-green;
      }
      a,
      a:focus,
      a:hover,
      a.active {
        color: $main-green;
      }
      .subItem:after,
      .subItem:hover:after {
        display: none !important;
      }
    }

    .service {
      color: $main-blueDark;
      border-bottom: 4px solid $main-blueDark;
      a:-webkit-any-link {
        color: $main-blueDark;
      }
      a,
      a:focus,
      a:hover,
      a.active {
        color: $main-blueDark;
      }
    }

    .about {
      color: $main-greenLight;
      border-bottom: 4px solid $main-greenLight;
      a:-webkit-any-link {
        color: $main-greenLight;
      }
      a,
      a:focus,
      a:hover,
      a.active {
        color: $main-greenLight;
      }
    }
  }
  .btn {
    position: absolute;
    top: 50px;
    right: 260px;
    padding: 17px 22px;
    background: $main-red;
    color: $main-white;
    font-size: 15px;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid $main-red;
    &:hover {
      color: $main-red;
      background: $main-white;
    }
  }
  .menu {
    position: absolute;
    width: 100%;
    height: 334px;
    background: rgba(35, 178, 232, 0.8);
    z-index: 99;
  }
  .active-home {
    background: rgba(35, 178, 232, 0.8);
    a,
    a:-webkit-any-link {
      color: #fff !important;
    }
  }

  .subItemWrap.active-play {
    background: #f15280;
  }
  .subItemWrap.active-vr {
    background: #fdd065;
  }

  .subItemWrap.active-service {
    background: $main-blueDark;
  }
  .subItemWrap.active-about {
    background: #0bbdb3;
  }

  .active-home,
  .active-play,
  .active-vr,
  .active-service,
  .active-about,
  .active-info {
    a {
      span {
        color: $main-white;
      }
    }
  }

  .active-play {
    background: rgba(230, 0, 66, 0.8);
    a,
    a:-webkit-any-link {
      color: #fff !important;
    }
  }

  .active-vr {
    background: rgba(215, 185, 42, 0.8);
    a,
    a:-webkit-any-link {
      color: #fff !important;
    }
  }

  .active-info {
    background: rgba(0, 130, 54, 0.8);
    a,
    a:-webkit-any-link {
      color: #fff !important;
    }
  }

  .active-service {
    background: rgba(3, 101, 171, 0.8);
    a,
    a:-webkit-any-link {
      color: #fff !important;
    }
  }

  .active-about {
    background: rgba(11, 189, 179, 0.8);
    a,
    a:-webkit-any-link {
      color: #fff !important;
    }
  }
}
@media (min-width: $screen-lg-min) and (max-width: $screen-sp-min) {
  .headWrap {
    .logo {
      left: 5%;
    }
    .btn {
      right: 6%;
    }
    .dateWrap {
      width: 1100px !important;
      // padding-left: 5%;
      // > .phone {
      //   right: 6%;
      // }
    }
  }
}
@media (max-width: $screen-lg-min) {
  .headWrap {
    .logoWrap {
      position: relative;
      display: block;
      height: 50px;
      padding-bottom: 10px;
      text-align: center;
      border: 1px solid transparent;
      background: $main-white;
    }
    .logo {
      left: 0;
      right: 0;
      top: 2px;
      margin: 0 auto;
    }
    .btn {
      position: relative;
      width: 106px;
      height: 53px;
      line-height: 53px;
      margin: 0 auto;
      top: 0;
      right: 0;
      padding: 0 0;
      text-align: center;
      background: #e60042;
      color: #fff;
      font-size: 15px;
      border-radius: 5px;
    }
    .dateWrap {
      padding-left: 5%;
      > .phone {
        right: 6%;
      }
    }
  }
}
</style>
