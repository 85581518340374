import {
  createStore
} from 'vuex'

export default createStore({
  state: {
    maskBeUsed: false,
    foodClick: false
  },
  // 用于同步修改state
  // 第一个参数state，第二个参数是需要修改的值
  mutations: {
    setHomeMaskShow(state, payLoad) {
      state.maskBeUsed = payLoad;
    },
    setFoodClickFlag(state, payLoad) {
      state.foodClick = payLoad;
    }
  },
  // 异步提交mutation
  actions: {},
  modules: {}
})
