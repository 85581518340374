<template>
  <div class="footerWrap">
    <ul class="footerTop">
      <li class="groupLeft">
        <img :src="require('../assets/image/head/phone.png')" alt />
        <div class="topRight">
          <span>咨询热线 400-661-5666</span>
          <span>浙江省乐清市大荆镇下山头村铁定溜溜 | 325600</span>
        </div>
        <div class="bottomWrap">
          <div class="bottomLeft">
            <span>
              <img :src="require('../assets/image/head/bus.png')" alt />
              交通信息
            </span>
            <span>乘坐512路到第五人民医院下车</span>
          </div>
          <div class="bottomRight">
            <span>
              <img :src="require('../assets/image/head/time.png')" alt />
              运营时间
            </span>
            <span>8:00-17:30</span>
          </div>
        </div>
      </li>
      <li class="groupCenter">
        <router-link to="/">
          <img :src="require('../assets/image/head/logo.png')" alt />
        </router-link>
      </li>
      <li class="groupRight">
        <img :src="require('../assets/image/head/code1.png')" alt />
        <span>微信公众号</span>
        <img :src="require('../assets/image/head/code2.png')" alt />
        <span>支付宝小程序</span>
      </li>
    </ul>
    <div class="footerInfo">
      Copyright@2019 乐清慢方适文化旅游有限公司版权所有 |
      <a
        class="link"
        href="https://beian.miit.gov.cn/"
        target="_blank"
      >浙ICP备19010689号-1</a> | 技术支持四川领创数智科技有限公司
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
export default {
  name: "m-footer-bar"
};
</script>

<style scoped lang="scss" rel="stylesheet/scss">
@import "src/assets/styles/variables.scss";
.footerWrap {
  // width: 1920px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid transparent;

  .footerTop {
    // width: 1920px;
    width: 100%;
    position: relative;
    height: 243px;
    background: $main-white;
    text-align: left;
    .groupLeft {
      // width: 639px;
      width: 34%;
      height: 100%;
      padding-top: 55px;
      padding-left: 13%;
      // padding-left: 260px; // width:30%;
      display: inline-block;
      box-sizing: border-box;
      overflow: hidden;

      img {
        margin-top: 2px;
        margin-left: 5px;
      }
      .topRight {
        margin-left: 14px;
        margin-right: 14px;
        padding-top: 15px;
        // float: right;
        display: inline-block;
        span {
          display: block;
          text-align: left;
        }
        span:nth-child(1) {
          color: $main-greenGrass;
          font-size: 19px;
        }
        span:nth-child(2) {
          color: $font-grayDark;
          font-size: 13px;
          padding-top: 10px;
        }
      }
      .bottomWrap {
        padding-top: 29px;
        .bottomLeft {
          width: 170px;
          // float: left;
          display: inline-block;
          margin-right: 30px;
          span {
            display: block;
            padding-left: 3px;
          }
          img {
            vertical-align: text-bottom;
          }
          span:nth-child(1) {
            font-size: 20px;
            color: $main-black;
          }
          span:nth-child(2) {
            margin-top: 9px;
            padding-top: 9px;
            font-size: 10px;
            color: $font-grayDark;
            border-top: 1px solid $font-grayDark;
          }
        }
        .bottomRight {
          width: 175px;
          // float: right;
          display: inline-block;

          span {
            display: block;
            padding-left: 3px;
          }
          img {
            vertical-align: text-bottom;
          }
          span:nth-child(1) {
            font-size: 20px;
            color: $main-black;
          }
          span:nth-child(2) {
            margin-top: 9px;
            padding-top: 9px;
            font-size: 10px;
            color: $font-grayDark;
            border-top: 1px solid $font-grayDark;
          }
        }
      }
    }
    .groupCenter {
      width: 420px;
      height: 100%;
      padding: 0px 194px 0px 90px;
      display: inline-block;
      img {
        position: absolute;
        top: 50px;
      }
      // background:red;
    }
    .groupRight {
      width: 290px;
      height: 100%;
      // background:yellow;
      display: inline-block;
      box-sizing: border-box;
      position: relative;

      img {
        position: absolute;
        top: 50px;
        width: 118px;
        height: 118px;
      }
      img:nth-of-type(1) {
        left: 0px;
      }
      img:nth-of-type(2) {
        // top: 45px;
        right: 0px;
        // width: 128px;
        // height: 127px;
      }
      span {
        position: absolute;
        display: block;
        width: 119px;
        text-align: center;
        font-size: 12px;
        color: $font-grayDark;
        top: 186px;
      }
      span:nth-of-type(1) {
        left: 0px;
      }
      span:nth-of-type(2) {
        right: 0px;
      }
    }
  }
  .footerInfo {
    width: 100%;
    // width: 1920px;
    height: 46px;
    line-height: 46px;
    background: $main-greenGrass;
    text-align: center;
    color: $main-white;
    .link {
      cursor: pointer;
    }
    a,
    a:hover,
    a:focus,
    a:active {
      color: $main-white;
    }
  }
}

@media (min-width: $screen-sp-min) and (max-width: $screen-ssp-min) {
  .footerWrap {
    .footerTop {
      .groupLeft {
        padding-left: 6%;
      }
      .groupCenter {
        padding: 0px 3% 0px 2%;
      }
    }
  }
}
@media (min-width: $screen-lg-min) and (max-width: $screen-sp-min) {
  .footerWrap {
    .footerTop {
      .groupLeft {
        padding-left: 3%;
      }
      .groupCenter {
        padding: 0 5% 0 0;
      }
    }
  }
}

@media (max-width: $screen-lg-min) {
  .footerWrap {
    .footerTop {
      .groupLeft {
        padding-left: 2px;
      }
      .groupCenter {
        padding: 0 10px 0 0;
      }
    }
  }
}
</style>
