<template>
  <div class="headWrap">
    <div class="gap"></div>
    <div class="contentWrap">
      <div class="dateWrap">
        <div class="content">
          <span class="phone">
            <img class="icon" :src="require('../assets/image/head_en/phone.png')" alt />
            <span>400-661-5666</span>
          </span>
          <span class="address">
            <img class="icon" :src="require('../assets/image/head_en/address.png')" alt />
            <span>Xia Shan Tou Cun, Dajing Town, Yueqing City, Zhejiang Province</span>
          </span>
          <!-- <span class="language">
            <img class="icon" :src="require('../assets/image/head_en/language.png')" alt />
            <span>Language</span>
          </span>-->
          <m-language-bar title="EN" bgcolor="#fff"></m-language-bar>
        </div>
      </div>
      <div class="bg">
        <div class="content">
          <router-link class="logoWrap" to="/home_en">
            <img class="logo ifocus_up" :src="require('../assets/image/head/logo_h.png')" alt />
          </router-link>
          <div class="menuWrap">
            <ul class="menuItemWrap" data-index="5">
              <li
                class="item home trans"
                :class="{'active-home':activeName==='home'}"
                data-index="0"
                data-name="home"
                @mouseenter="enter"
                @mouseleave="leave"
              >
                <router-link to="/home_en" :class="{'active':activeName==='home'}">
                  <img :src="require('../assets/image/head/index.png')" alt />
                  <span>Home Page</span>
                </router-link>
              </li>
              <li
                class="item play trans"
                :class="{'active-play':activeName==='play'}"
                data-index="1"
                data-name="play"
                @mouseenter="enter"
                @mouseleave="leave"
              >
                <router-link to :class="{'active':activeName==='play'}">
                  <img :src="require('../assets/image/head/ticket.png')" alt />
                  <span>Tourism strategy</span>
                </router-link>
                <ul class="subItemWrap play shadowBox_center">
                  <li class="subItem">
                    <router-link to="/summary_en">Introduction to the park</router-link>
                  </li>
                  <li class="subItem">
                    <router-link to="/food_en">Eat leisurely</router-link>
                  </li>
                  <li class="subItem">
                    <router-link to="/stay_en">Live leisurely</router-link>
                  </li>
                  <li class="subItem">
                    <router-link to="/play_en">Play leisurely</router-link>
                  </li>
                  <li class="subItem">
                    <router-link to="/buy_en">Buy leisurely</router-link>
                  </li>
                  <li class="subItem">
                    <router-link to="/ticket_en">Fare information</router-link>
                  </li>
                  <li class="subItem">
                    <router-link to="/route_en">Recommended route</router-link>
                  </li>
                </ul>
              </li>
              <li
                class="item vr trans"
                :class="{'active-vr':activeName==='vr'}"
                data-index="2"
                data-name="vr"
                @mouseenter="enter"
                @mouseleave="leave"
              >
                <router-link to :class="{'active':activeName==='vr'}">
                  <img :src="require('../assets/image/head/vr.png')" alt />
                  <span>VR and Video</span>
                </router-link>
                <ul class="subItemWrap vr shadowBox_center">
                  <li class="subItem">
                    <router-link to="/panorama_en">720°VR panorama</router-link>
                  </li>
                  <li class="subItem">
                    <router-link to="/video_en">Wonderful video</router-link>
                  </li>
                </ul>
              </li>
              <li
                class="item service trans"
                :class="{'active-service':activeName==='service'}"
                data-index="4"
                data-name="service"
                @mouseenter="enter"
                @mouseleave="leave"
              >
                <router-link to :class="{'active':activeName==='service'}">
                  <img :src="require('../assets/image/head/service.png')" alt />
                  <span>Tourism services</span>
                </router-link>
                <ul class="subItemWrap service shadowBox_center">
                  <li class="subItem">
                    <router-link to="/service_en/traffic_en">Traffic information</router-link>
                  </li>
                  <li class="subItem">
                    <router-link to="/service_en/notice_en">Travel instructions</router-link>
                  </li>
                  <li class="subItem">
                    <router-link to="/service_en/guide_en">Tour guide</router-link>
                  </li>
                </ul>
              </li>
              <li
                class="item about trans"
                :class="{'active-about':activeName==='about'}"
                data-index="5"
                data-name="about"
                @mouseenter="enter"
                @mouseleave="leave"
              >
                <router-link to="/company_en" :class="{'active':activeName==='about'}">
                  <img :src="require('../assets/image/head/about.png')" alt />
                  <span>About us</span>
                </router-link>
              </li>
            </ul>
          </div>
          <div class="btn trans" @click="book">Park ticket availability</div>
        </div>
      </div>
    </div>

    <!-- 遮罩 -->
    <div class="iMask" v-show="showMaskFlag">
      <div class="maskWrap">
        <img class="code" :src="require('../assets/image/food_en/code2.png')" alt />
        <img class="close" @click="closeMask" :src="require('../assets/image/stay/close.png')" alt />
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import Language from "@/components/Language";
export default {
  name: "m-head-bar",
  data() {
    return {
      showFlag: false,
      showMaskFlag: false,
      activeName: ""
    };
  },
  components: {
    [Language.name]: Language
  },
  methods: {
    enter(event) {
      this.showFlag = true;
      this.activeName = event.currentTarget.getAttributeNode("data-name").value;
      if (!this.activeName) {
        this.activeName = "";
      }
    },
    leave(event) {
      this.showFlag = false;
      this.activeName = "";
    },
    book() {
      this.showMaskFlag = true;
    },
    closeMask() {
      this.showMaskFlag = false;
    }
  }
};
</script>

<style scoped lang="scss" rel="stylesheet/scss">
@import "src/assets/styles/variables.scss";
// 遮罩
.iMask {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  background: rgba(0, 0, 0, 0.7);
  text-align: center;
  z-index: 99999;
  .maskWrap {
    position: absolute;
    width: 299px;
    height: 345px;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
  }
  .code {
    width: 305px;
    height: 343px;
    margin-bottom: 10px;
    cursor: default;
  }
  .close {
    cursor: pointer;
  }
}

.headWrap {
  .gap {
    width: 100%;
    height: 122px;
    // background: red;
  }

  .contentWrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99999;
    > .dateWrap {
      height: 33px;
      line-height: 33px;
      background: $main-blue;
      color: $main-white;
      > .content {
        position: relative;
        width: 1400px;
        margin: 0 auto;
        font-size: 14px;

        .icon {
          vertical-align: middle;
          margin-right: 6px;
        }
        .phone {
          display: inline-block;
          width: 130px;
          height: 19px;
          line-height: 19px;
          border-right: 1px solid $main-white;
        }
        .address {
          padding-left: 10px;
        }
        .language {
          position: absolute;
          right: 24px;
          cursor: pointer;
        }
      }
    }
    > .bg {
      height: 90px;
      background: $main-white;
      > .content {
        position: relative;
        width: 1400px;
        height: 88px;
        // background: red;
        margin: 0 auto;
        .logo {
          position: absolute;
          top: 50%;
          left: 0px;
          width: 140px;
          height: 50px;
          transform: translateY(-50%);
          cursor: pointer;
        }
        .menuWrap {
          text-align: center;
          background: $main-white;
          // box-sizing: border-box;
          .menuItemWrap {
            position: relative;
            display: inline;
            font-size: 17px;

            .item {
              position: relative;
              display: inline-block;
              width: 180px;
              padding-top: 14px;

              > a {
                display: block;
                height: 100%;
                border: 1px solid transparent;
                border-bottom: 4px solid transparent;

                img {
                  max-height: 34px;
                  font-weight: bold;
                  margin-bottom: 5px;
                }
                span {
                  display: block;
                  font-size: 18px;
                  margin-bottom: 7px;
                }
              }

              .subItemWrap {
                position: absolute;
                width: 180px;
                display: none;
                z-index: 100;
                // padding-bottom: 5px;
                background: $main-white;
                top: 89px;
                border-bottom-left-radius: 4px;
                border-bottom-right-radius: 4px;
                overflow: hidden;

                .subItem {
                  position: relative;
                  display: block;
                  height: 40px;

                  > a {
                    display: block;
                    // padding-top: 10px;
                    height: 40px;
                    line-height: 40px;
                    text-align: center;
                    font-size: 14px;
                    font-weight: bold;
                    color: $main-black;
                    // transition: all 0.2s ease-in;
                    // background:yellow;
                    &:hover {
                      color: $main-white;
                    }
                  }
                }
              }

              .subItemWrap.play {
                > .subItem {
                  > a:hover {
                    background: rgba(230, 0, 66, 0.2);
                  }
                  > a:focus,
                  > a.active {
                    color: $main-white;
                    background: rgba(230, 0, 66, 0.8);
                  }
                }
              }

              .subItemWrap.vr {
                > .subItem {
                  > a:hover {
                    background: rgba(251, 185, 42, 0.5);
                  }
                  > a:focus,
                  > a.active {
                    color: $main-white;
                    background: rgba(251, 185, 42, 0.8);
                  }
                }
              }

              .subItemWrap.service {
                > .subItem {
                  > a:hover {
                    background: rgba(3, 101, 171, 0.4);
                  }
                  > a:focus,
                  > a.active {
                    color: $main-white;
                    background: rgba(3, 101, 171, 0.9);
                  }
                }
              }

              &:hover .subItemWrap {
                display: block;
              }
            }

            .home {
              > a:-webkit-any-link {
                color: $main-blue;
              }
              > a:focus,
              > a.active,
              > a:hover {
                border-bottom: 4px solid $main-blue;
              }
            }

            .play {
              > a,
              > a:-webkit-any-link {
                color: $main-red;
              }
              > a:focus,
              > a:hover,
              > a.active {
                border-bottom: 5px solid $main-red;
              }
              .subItem {
                > a {
                  &:hover {
                    background: $main-red;
                  }
                }
                // > a:focus,
                // > a.active {
                //   background: $main-greenLight;
                // }
              }
            }

            .vr {
              > a,
              > a:-webkit-any-link {
                color: $main-yellow;
              }
              > a:focus,
              > a:hover,
              > a.active {
                color: $main-yellow;
                border-bottom: 5px solid $main-yellow;
              }
              .subItem {
                > a {
                  &:hover {
                    background: $main-yellow;
                  }
                }
                > a:focus,
                > a.active {
                  background: $main-greenLight;
                }
              }
            }

            .service {
              > a,
              > a:-webkit-any-link {
                color: $main-blueDark;
              }
              > a:focus,
              > a:hover,
              > a.active {
                color: $main-blueDark;
                border-bottom: 5px solid $main-blueDark;
              }
              .subItem {
                > a {
                  &:hover {
                    background: $main-blueDark;
                  }
                }
                > a:focus,
                > a.active {
                  background: $main-greenLight;
                }
              }
            }

            .about {
              > a,
              > a:-webkit-any-link {
                color: $main-greenLight;
              }
              > a:focus,
              > a:hover,
              > a.active {
                color: $main-greenLight;
                border-bottom: 4px solid $main-greenLight;
              }
              .subItem {
                > a {
                  &:hover {
                    background: $main-greenLight;
                  }
                }
                > a:focus,
                > a.active {
                  background: $main-greenLight;
                }
              }
            }
          }
        }
        .btn {
          position: absolute;
          top: 16px;
          right: 9px;
          padding: 17px 15px;
          background: $main-red;
          color: $main-white;
          font-size: 15px;
          border-radius: 5px;
          cursor: pointer;
          border: 1px solid $main-red;
          &:hover {
            color: $main-red;
            background: $main-white;
          }
        }
      }
    }
  }
}

@media (min-width: $screen-lg-min) and (max-width: $screen-sp-min) {
  .headWrap {
    .contentWrap {
      > .dateWrap {
        > .content {
          width: 1100px;
        }
      }
      > .bg {
        > .content {
          width: 1250px;
          .btn {
            right: -10px;
          }
        }
      }
    }
  }
}
</style>
